import React, {useEffect} from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import axios from "axios";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import '../assets/css/styles.css';
/* import RenderOnAnonymous from "./RenderOnAnonymous";
import RenderOnAuthenticated from "./RenderOnAuthenticated"; */
import RenderOnLogin from './RenderOnLogin';
import Landing from '../pages/Landing';
import Signup from '../pages/Signup';
import Applications from '../pages/Applications';
import Estimate from '../pages/Estimate';
import TermsOfService from '../pages/TermsOfService';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import Admin from '../pages/Admin';
import KYCValidation from '../pages/KYCValidation';

const serverConfig = require("./../config/server.js");

function App() {

  useEffect(() => {
    /* const cssJson = {
      colorDefault: "#000000",
      colorGreen: "#3EAA5C",
      fontSizeDefault: "14px",
      fontFamily: "'Lato', sans-serif",
      headerBgColor: "transparent",
      headerTxtColor: "#000000",
      headerIconColor: "#666666",
      color1: "#FDC60A",
      color2: "#FFF5D6",
      color3: "#80bdff",
      color4: "#007bff40",
      color5: "24, 124, 52",
    }; */

    axios.post(serverConfig.api_base_url + "get_favicon_image",{domainName:window.location.host})
    .then(response=>{
      console.log('get_favicon_image response', response);
      if(response.data.status === 200) {
        var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = response.data.faviconBase64;
        document.getElementsByTagName('head')[0].appendChild(link);
      } else {
        console.log(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));

    axios.post(serverConfig.api_base_url + "get_theme_css",{domainName:window.location.host})
    .then(response=>{
      console.log('get_theme_css response', response);
      if(response.data.status === 200) {
        const cssJson = JSON.parse(response.data.items);
        console.log(cssJson);
        const root = document.documentElement;

        root.style.setProperty('--colorDefault', cssJson.colorDefault);
        root.style.setProperty('--colorGreen', cssJson.colorGreen);
        root.style.setProperty('--fontSizeDefault', cssJson.fontSizeDefault);
        root.style.setProperty('--fontFamily', cssJson.fontFamily);
        root.style.setProperty('--headerBgColor', cssJson.headerBgColor);
        root.style.setProperty('--headerTxtColor', cssJson.headerTxtColor);
        root.style.setProperty('--headerIconColor', cssJson.headerIconColor);
        root.style.setProperty('--color1', cssJson.color1);
        root.style.setProperty('--color2', cssJson.color2);
        root.style.setProperty('--color3', cssJson.color3);
        root.style.setProperty('--color4', cssJson.color4);
        root.style.setProperty('--color5', cssJson.color5);
      } else {
        console.log(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }, []);

  return (
    <BrowserRouter>
      {/* <RenderOnAnonymous />
      <RenderOnAuthenticated>
        <Routes>
          <Route path="/" element={<Signup />} />
          <Route path="/users" element={<Users />} />
          <Route path="/applications" element={<Applications />} />
          <Route path="/prospect" element={<Estimate />} />
        </Routes>
      </RenderOnAuthenticated> */}
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/customer" element={<RenderOnLogin><Signup /></RenderOnLogin>} />
        <Route path="/applications" element={<RenderOnLogin><Applications /></RenderOnLogin>} />
        <Route path="/estimator" element={<Estimate />} />
        <Route path="/TermsOfService" element={<TermsOfService />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/admin" element={<RenderOnLogin><Admin /></RenderOnLogin>} />
        <Route path="/kyc-validation" element={<KYCValidation />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
