import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { QRCode } from 'react-qrcode-logo';
import axios from "axios";
import FileSaver from 'file-saver';

const serverConfig = require("../config/server.js");

const FormSubmit = ({ prevStep, nextStep, token, entityName, stepNum, stepNumber, stepLength, handleLogout, refreshSidebarCompletedStatus, submitDisabled, formSubmitted, handleNav, entityGroups }) => {

  
  const [isLoading, setIsLoading] = useState(false);
  
  const [inputs, setInputs] = useState({});
  const [inputsTimeframe, setInputsTimeframe] = useState({});
  const [groupData, setGroupData] = useState({});
  const [sectionData, setSectionData] = useState([]);

  const [stateLookupList, setStateLookupList] = useState([]);
  const [naicsCodeLookup, setNaicsCodeLookup] = useState([]);

  const[showMailingAddr, setShowMailingAddr] = useState(false);
  const[mailingAddrChecked, setMailingAddrChecked] = useState(false);
  const[isMailAddrRequired, setIsMailAddrRequired] = useState(false);
  const[isMailAddrDisabled, setIsMailAddrDisabled] = useState(true);

  const[showQrCode, setShowQrCode] = useState(false);
  const[verifyIDUrl, setVerifyIDUrl] = useState(null);
  const[qrCodesentMsg, setQrCodesentMsg] = useState(null);
  
  const[onboardingFormSubmitted, setOnboardingFormSubmitted] = useState(false);
  const[onboardingFormAccepted, setOnboardingFormAccepted] = useState(false);

  const[eaSubmitted, setEASubmitted] = useState(false);
  const[eaAccepted, setEAAccepted] = useState(false);
  const[sofSigned, setSofSigned] = useState(false);
  const[finalForm941xSigned, setFinalForm941xSigned] = useState(false);

  const[sendLinkDisabled, setSendLinkDisabled] = useState(true);
  const[businessOwnerDetails, setBusinessOwnerDetails] = useState({});

  const[authorizedSignerName, setAuthorizedSignerName] = useState(null);

  const[disruptionData, setDisruptionData] = useState([]);
  const[disruptionDataInputs, setDisruptionDataInputs] = useState({});
  const[addNewDisabled, setAddNewDisabled] = useState(true);

  const[cdCompanyName, setCdCompanyName] = useState(null);

  const[showAlertText, setShowAlertText] = useState(null);

  const [pendingEntitiesData, setPendingEntitiesData] = useState([]);

  const getGroupData = () => {
    sessionStorage.getItem('token', token);
    console.log(token);
    var formdata = {token:token, entity_group:entityName};
    console.log("formdata:", formdata);

    axios.post(serverConfig.api_base_url + "get_entity_group_data",formdata)
    .then(response=>{
      console.log('get_entity_group_data response', response);
      if(response.data.items !== null){
        let res = JSON.parse(response.data.items);
        console.log(res);
        console.log(res.group_sections);
        res.group_sections.sort(function(a,b){
          return a.section_seq_no - b.section_seq_no;
        });
        setGroupData(res);
        setSectionData(res.group_sections);

        let ma = res.group_sections.filter(section => section.section_name === 'mailing_address');
        if(ma.length > 0){
          if(ma[0].section_entities[0].entity_data){
            setShowMailingAddr(true);
            setIsMailAddrRequired(true);
            setIsMailAddrDisabled(false);
            setMailingAddrChecked(true);
          } else {
            setShowMailingAddr(false);
            setIsMailAddrRequired(false);
            setIsMailAddrDisabled(true);
            setMailingAddrChecked(false);
          }
        }

        let dp = res.group_sections.filter(section => section.section_name === 'statewise_disruption_data');
        if(dp.length > 0){
          console.log(JSON.parse(dp[0].section_entities[0].entity_data));
          if(dp[0].section_entities[0].entity_data !== null){
            setDisruptionData(JSON.parse(dp[0].section_entities[0].entity_data));
          }
        }

        let ldp = res.group_sections.filter(section => section.section_name === 'locationwise_disruption_data');
        if(ldp.length > 0){
          console.log(JSON.parse(ldp[0].section_entities[0].entity_data));
          if(ldp[0].section_entities[0].entity_data !== null){
            setDisruptionData(JSON.parse(ldp[0].section_entities[0].entity_data));
          }
        }

        let sd = res.group_sections.filter(section => section.section_name === "staffing_disruption_data");
        if(sd.length > 0){
          console.log(JSON.parse(sd[0].section_entities[0].entity_data));
          if(sd[0].section_entities[0].entity_data !== null){
            setInputsTimeframe(JSON.parse(sd[0].section_entities[0].entity_data));
          }
        }

        //onboarding form submitted
        let ofs = res.group_sections.filter(section => section.section_name === "submit_for_onboarding");
        if(ofs.length > 0){
          let ofs_submitted = ofs[0].section_entities.filter(entities => entities.entity_name === "OnboardingSubmitted");
          console.log(ofs_submitted[0].entity_data);
          if(ofs_submitted[0].entity_data !== null){
            setOnboardingFormSubmitted(true);
          }
        }
        //onboarding form accepted
        let ofa = res.group_sections.filter(section => section.section_name === "submit_for_onboarding");
        if(ofa.length > 0){
          let ofa_accepted = ofa[0].section_entities.filter(entities => entities.entity_name === "OnboardingAccepted");
          console.log(ofa_accepted[0].entity_data);
          if(ofa_accepted[0].entity_data !== null){
            setOnboardingFormAccepted(true);
          }
        }

        //EA submitted
        /* let eas = res.group_sections.filter(section => section.section_name === "signed_engagement_agreement_wet_signature");
        if(eas.length > 0){
          let eas_submitted = eas[0].section_entities.filter(entities => entities.entity_name === "EngagementAgreementWSSubmitted");
          console.log(eas_submitted[0].entity_data);
          if(eas_submitted[0].entity_data !== null){
            setEASubmitted(true);
          }
        } */
        
        let eaa = res.group_sections.filter(section => section.section_name === "signed_engagement_agreement_wet_signature");
        if(eaa.length > 0){
          let eaa_submitted = eaa[0].section_entities.filter(entities => entities.entity_name === "EngagementAgreementWSAccepted");
          console.log(eaa_submitted[0].entity_data);
          if(eaa_submitted[0].entity_data !== null){
            setEAAccepted(true);
          }
        }

        let sofs = res.group_sections.filter(section => section.section_name === "signed_statement_of_fact_file");
        if(sofs.length > 0){
          let sof_signed = sofs[0].section_entities.filter(entities => entities.entity_name === "SignedStatementOfFactFile");
          console.log(sof_signed[0].entity_data);
          if(sof_signed[0].entity_data !== null){
            setSofSigned(true);
          }
        }
        let final941xsigned = res.group_sections.filter(section => section.section_name === "signed_final_form_941x");
        if(final941xsigned.length > 0){
          let final_form_941x_signed = final941xsigned[0].section_entities.filter(entities => entities.entity_name === "SignedFinalForm941x");
          console.log(final_form_941x_signed[0].entity_data);
          if(final_form_941x_signed[0].entity_data !== null){
            setFinalForm941xSigned(true);
          }
        }

        let cd = res.group_sections.filter(section => section.section_name === 'customer_data');
        console.log(cd);
        if(cd.length > 0){
          console.log(cd[0].section_entities[0].entity_data);
          if(cd[0].section_entities[0].entity_data !== null){
            setCdCompanyName(cd[0].section_entities[0].entity_data);
          }
        }

        let ci = res.group_sections.filter(section => section.section_name === "company_info");
        console.log(ci);
        if(ci.length > 0){
          console.log(ci[0].section_entities[0].entity_data);
          let industry = ci[0].section_entities.filter(entities => entities.entity_name === "Industry");
          console.log(industry[0].entity_data);
          setTimeout(() => {
            
            if(industry[0].entity_data === 'Banks') {
              document.getElementsByName('IsBusinessInList')[0].closest(".mb-4").style.display = "none";
              document.getElementsByName('IsBusinessInList')[0].disabled = true;
              document.getElementsByName('IsBusinessInList')[1].disabled = true;
              document.getElementsByName('IsBusinessInList')[0].required = false;
              var ele1 = document.getElementsByName("IsBusinessInList");
              for(var i=0;i<ele1.length;i++)
                  ele1[i].checked = false;
              if(document.getElementsByName('IsBusinessInList')[0].closest('.d-flex').previousSibling.querySelector(".txt-red") !== null){
                document.getElementsByName('IsBusinessInList')[0].closest('.d-flex').previousSibling.querySelector(".txt-red").remove();
              }
      
              /* document.getElementsByName('IsFarmingBusiness')[0].closest(".mb-4").style.display = "none";
              document.getElementsByName('IsFarmingBusiness')[0].disabled = true;
              document.getElementsByName('IsFarmingBusiness')[1].disabled = true;
              document.getElementsByName('IsFarmingBusiness')[0].required = false;
              var ele2 = document.getElementsByName("IsFarmingBusiness");
              for(var i=0;i<ele2.length;i++)
                ele2[i].checked = false;
              if(document.getElementsByName('IsFarmingBusiness')[0].closest('.d-flex').previousSibling.querySelector(".txt-red") !== null){
                document.getElementsByName('IsFarmingBusiness')[0].closest('.d-flex').previousSibling.querySelector(".txt-red").remove();
              }
      
              document.getElementsByName('PlanToSeparateBusiness')[0].closest(".mb-4").style.display = "none";
              document.getElementsByName('PlanToSeparateBusiness')[0].disabled = true;
              document.getElementsByName('PlanToSeparateBusiness')[1].disabled = true;
              document.getElementsByName('PlanToSeparateBusiness')[0].required = false;
              var ele3 = document.getElementsByName("PlanToSeparateBusiness");
              for(var i=0;i<ele3.length;i++)
                ele3[i].checked = false;
              if(document.getElementsByName('PlanToSeparateBusiness')[0].closest('.d-flex').previousSibling.querySelector(".txt-red") !== null){
                document.getElementsByName('PlanToSeparateBusiness')[0].closest('.d-flex').previousSibling.querySelector(".txt-red").remove();
              } */
            } else {
              document.getElementsByName('IsBusinessInList')[0].closest(".mb-4").style.display = "block";
              document.getElementsByName('IsBusinessInList')[0].disabled = false;
              document.getElementsByName('IsBusinessInList')[1].disabled = false;
              document.getElementsByName('IsBusinessInList')[0].required = true;
              document.getElementsByName('IsBusinessInList')[0].closest('.d-flex').previousSibling.innerHTML += '<span class="txt-red">*</span>';
              
              /* document.getElementsByName('IsFarmingBusiness')[0].closest(".mb-4").style.display = "block";
              document.getElementsByName('IsFarmingBusiness')[0].disabled = false;
              document.getElementsByName('IsFarmingBusiness')[1].disabled = false;
              document.getElementsByName('PlanToSeparateBusiness')[0].closest(".mb-4").style.display = "block";
              document.getElementsByName('PlanToSeparateBusiness')[0].disabled = false;
              document.getElementsByName('PlanToSeparateBusiness')[1].disabled = false; */
            }
          }, 100);
        }

        let ei = res.group_sections.filter(section => section.section_name === "employee_info_bottom");
        console.log(ei);
        if(ei.length > 0){
          console.log(ei[0].section_entities[0].entity_data);
          let taken_credits = ei[0].section_entities.filter(entities => entities.entity_name === "TakenCredits");
          console.log(taken_credits[0].entity_data);
          setTimeout(() => {
            if(taken_credits[0].entity_data === 'Yes') {
              document.getElementsByName('CreditsTimeframeFrom')[0].disabled = false;
              document.getElementsByName('CreditsTimeframeFrom')[0].required = true;
              document.getElementsByName('CreditsTimeframeFrom')[0].previousSibling.innerHTML += '<span class="txt-red">*</span>';
              document.getElementsByName('CreditsTimeframeTo')[0].disabled = false;
              document.getElementsByName('CreditsTimeframeTo')[0].required = true;
              document.getElementsByName('CreditsTimeframeTo')[0].previousSibling.innerHTML += '<span class="txt-red">*</span>';
            } else if(taken_credits[0].entity_data === 'No') {
              document.getElementsByName('CreditsTimeframeFrom')[0].disabled = true;
              document.getElementsByName('CreditsTimeframeFrom')[0].required = false;
              if(document.getElementsByName('CreditsTimeframeFrom')[0].previousSibling.querySelector(".txt-red") !== null){
                document.getElementsByName('CreditsTimeframeFrom')[0].previousSibling.querySelector(".txt-red").remove();
              }
      
              document.getElementsByName('CreditsTimeframeTo')[0].disabled = true;
              document.getElementsByName('CreditsTimeframeTo')[0].required = false;
              if(document.getElementsByName('CreditsTimeframeTo')[0].previousSibling.querySelector(".txt-red") !== null){
                document.getElementsByName('CreditsTimeframeTo')[0].previousSibling.querySelector(".txt-red").remove();
              }
            }
          }, 100);
        }
      } else {
        setGroupData({});
        setSectionData([]);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const getLookupList = () => {
    axios.post(serverConfig.api_base_url + "get_lookup_list",{token:token, name:"state"})
    .then(response=>{
      console.log('get_lookup_list response', response);
      if(response.data !== null){
        setStateLookupList(response.data);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const getPendingEntities = () => {
    axios.post(serverConfig.api_base_url + "get_application_pending_entities_user",{token:token})
    .then(response=>{
      console.log('get_application_pending_entities_user response', response);
      if(response.data.status === 200){
        let items = JSON.parse(response.data.items);
        if (items !== null && items !== undefined){
          console.log("get_application_pending_entities_user response items:", items);
          setPendingEntitiesData(items);
        } else {
          setPendingEntitiesData([]);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    setInputs({});
    setGroupData({});
    setSectionData([]);
		getGroupData();
    getLookupList();
    setShowQrCode(false);
    setAddNewDisabled(true);
    setDisruptionData([]);
    setDisruptionDataInputs({});

    if(entityName === "business_owner_id"){
      axios.post(serverConfig.api_base_url + "get_entity_group_data",{token:token, entity_group:"business_owner"})
      .then(response=>{
        console.log('get_entity_group_data response', response);
        if(response.data.items !== null){
          let res = JSON.parse(response.data.items);
          console.log(res);
          //console.log(res.group_sections[0].section_entities);
          let bo = res.group_sections.filter(section => section.section_name === "business_owner");
          let bo_name = bo[0].section_entities.filter(entities => entities.entity_name === "BusinessOwnerFullName");
          let bo_email = bo[0].section_entities.filter(entities => entities.entity_name === "BusinessOwnerEmail");
          console.log(bo_name[0].entity_data, bo_email[0].entity_data);
          setBusinessOwnerDetails({
            name:bo_name[0].entity_data,
            email:bo_email[0].entity_data
          });
          if(bo_name[0].entity_data !== null && bo_email[0].entity_data !== null){
            setSendLinkDisabled(false);
          } else {
            setSendLinkDisabled(true);
          }
        }
      })
      .catch(err=>console.log("error is",err));
      //user_details
      axios.post(serverConfig.api_base_url + "get_entity_group_data",{token:token, entity_group:"user_details"})
      .then(response=>{
        console.log('get_entity_group_data response', response);
        if(response.data.items !== null){
          let res = JSON.parse(response.data.items);
          console.log(res);
          //console.log(res.group_sections[0].section_entities);
          let ud = res.group_sections.filter(section => section.section_name === "personal_info");
          let ud_business_owner = ud[0].section_entities.filter(entities => entities.entity_name === "IsBusinessOwner");
          console.log(ud_business_owner[0].entity_data);
          if(ud_business_owner[0].entity_data !== null && ud_business_owner[0].entity_data === "Yes"){
            axios.post(serverConfig.api_base_url + "get_kyc_token",{token:token, entity_group: 'business_owner_id', entity_name: 'DriversLicense'})
            .then(response=>{
              console.log(response);
              if(response.data.status === 200) {
                console.log('success', window.location);
                let url = window.location.origin+"/kyc-validation?"+response.data.kyc_token;
                console.log(url);
                setVerifyIDUrl(url);
                setShowQrCode(true);
              } else if(response.data.status === 301){
                handleLogout();
              } else {
                alert(response.data.message);
              }
            })
            .catch(err=>console.log("error is",err));
          } else {
            setShowQrCode(false);
          }
        }
      })
      .catch(err=>console.log("error is",err));
    }

    if(entityName === "statement_of_fact"){
      axios.post(serverConfig.api_base_url + "get_entity_group_data",{token:token, entity_group:"authorized_signer"})
      .then(response=>{
        console.log('get_entity_group_data response', response);
        if(response.data.items !== null){
          let res = JSON.parse(response.data.items);
          console.log(res);
          console.log(res.group_sections[0].section_entities);
          let as = res.group_sections.filter(section => section.section_name === "authorized_signer");
          let as_name = as[0].section_entities.filter(entities => entities.entity_name === "AuthorizedSignerFullName");
          setAuthorizedSignerName(as_name[0].entity_data);
        }
      })
      .catch(err=>console.log("error is",err));
    }

    if(entityName === "agreements_disclosures"){
      getPendingEntities();
    }

    if(entityName === "business_details"){
      axios.post(serverConfig.api_base_url + "get_naics_code_list",{token:token})
      .then(response=>{
        console.log('get_naics_code_list response', response);
        if(response.data.items !== null){
          console.log('get_naics_code_list items', JSON.parse(response.data.items));
          setNaicsCodeLookup(JSON.parse(response.data.items));
        }
      })
      .catch(err=>console.log("error is",err));
    }
	},[entityName]);

  const handleDiffMailAddr = (event) => {
    if (event.target.checked) {
      setShowMailingAddr(true);
      setIsMailAddrRequired(true);
      setIsMailAddrDisabled(false);
      setMailingAddrChecked(true);
    } else {
      setShowMailingAddr(false);
      setIsMailAddrRequired(false);
      setIsMailAddrDisabled(true);
      setMailingAddrChecked(false);

      document.getElementsByName('MailingAddressLine1')[0].value = '';
      document.getElementsByName('MailingAddressLine2')[0].value = '';
      document.getElementsByName('MailingCity')[0].value = '';
      document.getElementsByName('MailingState')[0].value = '';
      document.getElementsByName('MailingPostalCode')[0].value = '';
      setInputs({
        ...inputs,
        MailingAddressLine1:'',
        MailingAddressLine2:'',
        MailingCity:'',
        MailingState:'',
        MailingPostalCode:'',
      });
    }
  }

  const handleChange = (event) => {
    //console.log('changing', event, event.target.value);
    const name = event.target.name;
    let value = event.target.value;
    let custDataType = event.target.dataset.customDatatype;

    const newData={...inputs}

    if(event.target.type === 'textarea'){
      if(value !== ''){
        value = value.replace(/(\r\n|\r|\n)/g, '<br />').replace(/'/g, '&apos;');
      }
    }

    if(event.target.type === 'text'){
      if(value !== ''){
        value = value.replace(/'/g, '&apos;');
      }
    }

    if(event.target.name === "PayrollProvider"){
      console.log(event.target.value);
      if(event.target.value === "Other") {
        document.getElementsByName('PayrollProviderOther')[0].disabled = false;
        document.getElementsByName('PayrollProviderOther')[0].required = true;
        document.getElementsByName('PayrollProviderOther')[0].previousSibling.innerHTML += '<span class="txt-red">*</span>';
      } else {
        newData['PayrollProviderOther']='';
        document.getElementsByName('PayrollProviderOther')[0].value = '';
        document.getElementsByName('PayrollProviderOther')[0].disabled = true;
        document.getElementsByName('PayrollProviderOther')[0].required = false;
        if(document.getElementsByName('PayrollProviderOther')[0].previousSibling.querySelector(".txt-red") !== null){
          document.getElementsByName('PayrollProviderOther')[0].previousSibling.querySelector(".txt-red").remove();
        }
      }
    }

    if(event.target.name === "QualifyBasedOnGR"){
      console.log(event.target.value);
      let i;
      let el = event.target.closest('fieldset').querySelectorAll('input[data-custom-datatype="amount"]')
      if(event.target.value === "No") {
        for(i=0; i < el.length; i++){
          el[i].required = false;
          el[i].placeholder = el[i].placeholder.replace("*", "");
        }
      } else {
        for(i=0; i < el.length; i++){
          if(!el[i].name.includes("2020")){
            el[i].required = true;
            if(!el[i].placeholder.includes("*")){
              el[i].placeholder = el[i].placeholder+'*';
            }
          }
        }
      }
    }

    if(custDataType === "amount" && value !== ''){
      var pattern = /^[0-9,]*$/g;
      if(value.match(pattern)){
        let withoutComma = value.replace(/,/g, '');
        event.target.value = Number(withoutComma).toLocaleString("en-US");
        value = Number(withoutComma).toLocaleString("en-US");
      } else {
        event.target.value = value.substring(0, value.length - 1);
        value = value.substring(0, value.length - 1);
      }
    }

    if(name==="EmployeesOwnMoreThan5PerBusiness"){
      if(value === 'Yes') {
        document.getElementById('EmployeeNames').required = true;
        document.getElementById("EmployeeNames").placeholder = document.getElementById("EmployeeNames").placeholder+'*';
      } else if(value === 'No') {
        document.getElementById('EmployeeNames').required = false;
        document.getElementById("EmployeeNames").placeholder = document.getElementById("EmployeeNames").placeholder.slice(0,-1);
      }
    }

    /* covid-19 banks validation - start */
    //Locations and Facilities considerations
    if(name==="AnyLocationsShutdown"){
      if(value === 'Yes') {
        document.getElementById('locationWiseDisruptionDataEmpty').disabled = false;
        document.getElementById('disruption-periods').previousSibling.innerHTML += '<span class="txt-red">*</span>';
      } else if(value === 'No') {
        document.getElementById('locationWiseDisruptionDataEmpty').disabled = true;
        console.log(document.querySelector('#locationWiseDisruptionDataEmpty input[name="DisruptionLocation"]').value);
        document.querySelector('#locationWiseDisruptionDataEmpty input[name="DisruptionLocation"]').value = "";
        document.querySelector('#locationWiseDisruptionDataEmpty input[name="DisruptionLocStartDate"]').value = "";
        document.querySelector('#locationWiseDisruptionDataEmpty input[name="DisruptionLocEndDate"]').value = "";
        document.querySelector('#locationWiseDisruptionDataEmpty input[name="DisruptionLocEndDate"]').min = "2020-01-01";
        document.querySelector('#locationWiseDisruptionDataEmpty textarea[name="DisruptionLocComments"]').value = "";
        newData["LocationwiseDisruptionData"] = "";
        setDisruptionData([]);
        setDisruptionDataInputs({});
        setAddNewDisabled(true);
        if(document.getElementById('disruption-periods').previousSibling.querySelector(".txt-red") !== null){
          document.getElementById('disruption-periods').previousSibling.querySelector(".txt-red").remove();
        }
      }
    }
    if(name==="BankProvidedEquipment"){
      if(value === 'Yes') {
        document.getElementsByName('BankProvidedEquipmentCost')[0].disabled = false;
        document.getElementsByName('BankProvidedEquipmentCost')[0].required = true;
        document.getElementsByName('BankProvidedEquipmentCost')[0].previousSibling.innerHTML += '<span class="txt-red">*</span>';
        document.getElementsByName('BankProvidedEquipmentDoc')[0].disabled = false;
        document.getElementsByName('BankProvidedEquipmentDoc')[0].required = true;
      } else if(value === 'No') {
        document.getElementsByName('BankProvidedEquipmentCost')[0].disabled = true;
        document.getElementsByName('BankProvidedEquipmentCost')[0].required = false;
        document.getElementsByName('BankProvidedEquipmentCost')[0].value = "";
        if(document.getElementsByName('BankProvidedEquipmentCost')[0].previousSibling.querySelector(".txt-red") !== null){
          document.getElementsByName('BankProvidedEquipmentCost')[0].previousSibling.querySelector(".txt-red").remove();
        }
        newData["BankProvidedEquipmentCost"] = "";
        document.getElementsByName('BankProvidedEquipmentDoc')[0].disabled = true;
        document.getElementsByName('BankProvidedEquipmentDoc')[0].required = false;
      }
    }
    //Staffing considerations
    if(name==="ImpactToSupportFunctions"){
      if(value === 'Yes') {
        document.getElementsByName('ImpactToSupportFunctionsExplanation')[0].disabled = false;
        document.getElementsByName('ImpactToSupportFunctionsExplanation')[0].required = true;
        document.getElementsByName('ImpactToSupportFunctionsExplanation')[0].previousSibling.innerHTML += '<span class="txt-red">*</span>';
        document.getElementsByName('ImpactToSupportFunctionsExplanationDoc')[0].disabled = false;
        document.getElementsByName('ImpactToSupportFunctionsExplanationDoc')[0].required = true;
      } else if(value === 'No') {
        document.getElementsByName('ImpactToSupportFunctionsExplanation')[0].disabled = true;
        document.getElementsByName('ImpactToSupportFunctionsExplanation')[0].required = false;
        document.getElementsByName('ImpactToSupportFunctionsExplanation')[0].value = "";
        if(document.getElementsByName('ImpactToSupportFunctionsExplanation')[0].previousSibling.querySelector(".txt-red") !== null){
          document.getElementsByName('ImpactToSupportFunctionsExplanation')[0].previousSibling.querySelector(".txt-red").remove();
        }
        newData["ImpactToSupportFunctionsExplanation"] = "";
        document.getElementsByName('ImpactToSupportFunctionsExplanationDoc')[0].disabled = true;
        document.getElementsByName('ImpactToSupportFunctionsExplanationDoc')[0].required = false;
      }
    }
    if(name==="AnticipatedHiringPlansAffected"){
      if(value === 'Yes') {
        document.getElementsByName('AnticipatedHiringPlansAffectedExplanation')[0].disabled = false;
        document.getElementsByName('AnticipatedHiringPlansAffectedExplanation')[0].required = true;
        document.getElementsByName('AnticipatedHiringPlansAffectedExplanation')[0].previousSibling.innerHTML += '<span class="txt-red">*</span>';
        document.getElementsByName('AnticipatedHiringPlansAffectedExplanationDoc')[0].disabled = false;
        document.getElementsByName('AnticipatedHiringPlansAffectedExplanationDoc')[0].required = true;
      } else if(value === 'No') {
        document.getElementsByName('AnticipatedHiringPlansAffectedExplanation')[0].disabled = true;
        document.getElementsByName('AnticipatedHiringPlansAffectedExplanation')[0].required = false;
        document.getElementsByName('AnticipatedHiringPlansAffectedExplanation')[0].value = "";
        if(document.getElementsByName('AnticipatedHiringPlansAffectedExplanation')[0].previousSibling.querySelector(".txt-red") !== null){
          document.getElementsByName('AnticipatedHiringPlansAffectedExplanation')[0].previousSibling.querySelector(".txt-red").remove();
        }
        newData["AnticipatedHiringPlansAffectedExplanation"] = "";
        document.getElementsByName('AnticipatedHiringPlansAffectedExplanationDoc')[0].disabled = true;
        document.getElementsByName('AnticipatedHiringPlansAffectedExplanationDoc')[0].required = false;
      }
    }
    if(name==="ReductionInServices"){
      if(value === 'Yes') {
        document.getElementsByName('ReductionInServicesExplanation')[0].disabled = false;
        document.getElementsByName('ReductionInServicesExplanation')[0].required = true;
        document.getElementsByName('ReductionInServicesExplanation')[0].previousSibling.innerHTML += '<span class="txt-red">*</span>';
        document.getElementsByName('ReductionInServicesExplanationDoc')[0].disabled = false;
        document.getElementsByName('ReductionInServicesExplanationDoc')[0].required = true;
      } else if(value === 'No') {
        document.getElementsByName('ReductionInServicesExplanation')[0].disabled = true;
        document.getElementsByName('ReductionInServicesExplanation')[0].required = false;
        document.getElementsByName('ReductionInServicesExplanation')[0].value = "";
        if(document.getElementsByName('ReductionInServicesExplanation')[0].previousSibling.querySelector(".txt-red") !== null){
          document.getElementsByName('ReductionInServicesExplanation')[0].previousSibling.querySelector(".txt-red").remove();
        }
        newData["ReductionInServicesExplanation"] = "";
        document.getElementsByName('ReductionInServicesExplanationDoc')[0].disabled = true;
        document.getElementsByName('ReductionInServicesExplanationDoc')[0].required = false;
      }
    }
    if(name==="ITTrackedEmployees"){
      if(value === 'Yes') {
        document.getElementsByName('EmployeeReportedOutagesDetails')[0].disabled = false;
        document.getElementsByName('EmployeeReportedOutagesDetails')[0].required = true;
        document.getElementsByName('EmployeeReportedOutagesDetails')[0].previousSibling.innerHTML += '<span class="txt-red">*</span>';
        document.getElementsByName('EmployeeReportedOutagesDoc')[0].disabled = false;
        document.getElementsByName('EmployeeReportedOutagesDoc')[0].required = true;
      } else if(value === 'No') {
        document.getElementsByName('EmployeeReportedOutagesDetails')[0].disabled = true;
        document.getElementsByName('EmployeeReportedOutagesDetails')[0].required = false;
        document.getElementsByName('EmployeeReportedOutagesDetails')[0].value = "";
        if(document.getElementsByName('EmployeeReportedOutagesDetails')[0].previousSibling.querySelector(".txt-red") !== null){
          document.getElementsByName('EmployeeReportedOutagesDetails')[0].previousSibling.querySelector(".txt-red").remove();
        }
        newData["EmployeeReportedOutagesDetails"] = "";
        document.getElementsByName('EmployeeReportedOutagesDoc')[0].disabled = true;
        document.getElementsByName('EmployeeReportedOutagesDoc')[0].required = false;
      }
    }
    //HR considerations
    if(name==="EmployeesCouldNotWfh"){
      if(value === 'Yes') {
        document.getElementsByName('EmployeesNumberPeriod')[0].disabled = false;
        document.getElementsByName('EmployeesNumberPeriod')[0].required = true;
        document.getElementsByName('EmployeesNumberPeriod')[0].previousSibling.innerHTML += '<span class="txt-red">*</span>';

        document.getElementsByName('PaidIfNotWorked')[0].disabled = false;
        document.getElementsByName('PaidIfNotWorked')[1].disabled = false;
        document.getElementsByName('PaidIfNotWorked')[0].required = true;
        document.getElementsByName('PaidIfNotWorked')[0].closest(".d-flex").previousSibling.innerHTML += '<span class="txt-red">*</span>';

        document.getElementsByName('EmployeesCouldNotWfhDoc')[0].disabled = false;
        document.getElementsByName('PaidIfNotWorkedDoc')[0].disabled = false;
      } else if(value === 'No') {
        document.getElementsByName('EmployeesNumberPeriod')[0].disabled = true;
        document.getElementsByName('EmployeesNumberPeriod')[0].required = false;
        document.getElementsByName('EmployeesNumberPeriod')[0].value = "";
        if(document.getElementsByName('EmployeesNumberPeriod')[0].previousSibling.querySelector(".txt-red") !== null){
          document.getElementsByName('EmployeesNumberPeriod')[0].previousSibling.querySelector(".txt-red").remove();
        }
        newData["EmployeesNumberPeriod"] = "";

        document.getElementsByName('PaidIfNotWorked')[0].disabled = true;
        document.getElementsByName('PaidIfNotWorked')[1].disabled = true;
        document.getElementsByName('PaidIfNotWorked')[0].required = false;
        document.getElementsByName('PaidIfNotWorked')[0].checked = false;
        document.getElementsByName('PaidIfNotWorked')[1].checked = false;
        if(document.getElementsByName('PaidIfNotWorked')[0].closest(".d-flex").previousSibling.querySelector(".txt-red") !== null){
          document.getElementsByName('PaidIfNotWorked')[0].closest(".d-flex").previousSibling.querySelector(".txt-red").remove();
        }
        newData["PaidIfNotWorked"] = "";
        document.getElementsByName('EmployeesCouldNotWfhDoc')[0].disabled = true;
        document.getElementsByName('PaidIfNotWorkedDoc')[0].disabled = true;
      }
    }
    if(name==="EmployeesFurloughed"){
      if(value === 'Yes') {
        document.getElementsByName('EmployeesNumberFurloughedPeriod')[0].disabled = false;
        document.getElementsByName('EmployeesNumberFurloughedPeriod')[0].required = true;
        document.getElementsByName('EmployeesNumberFurloughedPeriod')[0].previousSibling.innerHTML += '<span class="txt-red">*</span>';
        document.getElementsByName('EmployeesFurloughedDoc')[0].disabled = false;
        document.getElementsByName('HealthcareCost')[0].disabled = false;
        document.getElementsByName('HealthcareCost')[0].required = true;
        document.getElementsByName('HealthcareCost')[0].previousSibling.innerHTML += '<span class="txt-red">*</span>';
        document.getElementsByName('HealthcareCostDoc')[0].disabled = false;
      } else if(value === 'No') {
        document.getElementsByName('EmployeesNumberFurloughedPeriod')[0].disabled = true;
        document.getElementsByName('EmployeesNumberFurloughedPeriod')[0].required = false;
        document.getElementsByName('EmployeesNumberFurloughedPeriod')[0].value = "";
        if(document.getElementsByName('EmployeesNumberFurloughedPeriod')[0].previousSibling.querySelector(".txt-red") !== null){
          document.getElementsByName('EmployeesNumberFurloughedPeriod')[0].previousSibling.querySelector(".txt-red").remove();
        }
        newData["EmployeesNumberFurloughedPeriod"] = "";
        document.getElementsByName('EmployeesFurloughedDoc')[0].disabled = true;
    
        document.getElementsByName('HealthcareCost')[0].disabled = true;
        document.getElementsByName('HealthcareCost')[0].required = false;
        document.getElementsByName('HealthcareCost')[0].value = "";
        if(document.getElementsByName('HealthcareCost')[0].previousSibling.querySelector(".txt-red") !== null){
          document.getElementsByName('HealthcareCost')[0].previousSibling.querySelector(".txt-red").remove();
        }
        newData["HealthcareCost"] = "";
        document.getElementsByName('HealthcareCostDoc')[0].disabled = true;
      }
    }
    if(name==="PPPLoansApplicable"){
      if(value === 'Yes') {
        document.getElementsByName('PPP1LoanNumber')[0].required = true;
        document.getElementsByName('PPP1LoanNumber')[0].previousSibling.innerHTML += '<span class="txt-red">*</span>';
      } else if(value === 'No') {
        document.getElementsByName('PPP1LoanNumber')[0].required = false;
        document.getElementsByName('PPP1LoanNumber')[0].value = "";
        if(document.getElementsByName('PPP1LoanNumber')[0].previousSibling.querySelector(".txt-red") !== null){
          document.getElementsByName('PPP1LoanNumber')[0].previousSibling.querySelector(".txt-red").remove();
        }
        newData["PPP1LoanNumber"] = "";
      }
    }
    /* covid-19 banks validation - end */

    if(name==="RequiredToFile1094C"){
      if(value === 'Yes') {
        document.getElementsByName('File1094CDoc')[0].disabled = false;
        document.getElementsByName('File1094CDoc')[0].required = true;
      } else if(value === 'No') {
        document.getElementsByName('File1094CDoc')[0].disabled = true;
        document.getElementsByName('File1094CDoc')[0].required = false;
      }
    }

    if(name==="Industry"){
      if(value === 'Banks') {
        document.getElementsByName('IsBusinessInList')[0].closest(".mb-4").style.display = "none";
        document.getElementsByName('IsBusinessInList')[0].disabled = true;
        document.getElementsByName('IsBusinessInList')[1].disabled = true;
        document.getElementsByName('IsBusinessInList')[0].required = false;
        var ele1 = document.getElementsByName("IsBusinessInList");
        for(var i=0;i<ele1.length;i++)
            ele1[i].checked = false;
        if(document.getElementsByName('IsBusinessInList')[0].closest('.d-flex').previousSibling.querySelector(".txt-red") !== null){
          document.getElementsByName('IsBusinessInList')[0].closest('.d-flex').previousSibling.querySelector(".txt-red").remove();
        }
        newData["IsBusinessInList"] = "";

        /* document.getElementsByName('IsFarmingBusiness')[0].closest(".mb-4").style.display = "none";
        document.getElementsByName('IsFarmingBusiness')[0].disabled = true;
        document.getElementsByName('IsFarmingBusiness')[1].disabled = true;
        document.getElementsByName('IsFarmingBusiness')[0].required = false;
        var ele2 = document.getElementsByName("IsFarmingBusiness");
        for(var i=0;i<ele2.length;i++)
          ele2[i].checked = false;
        if(document.getElementsByName('IsFarmingBusiness')[0].closest('.d-flex').previousSibling.querySelector(".txt-red") !== null){
          document.getElementsByName('IsFarmingBusiness')[0].closest('.d-flex').previousSibling.querySelector(".txt-red").remove();
        }
        newData["IsFarmingBusiness"] = "";

        document.getElementsByName('PlanToSeparateBusiness')[0].closest(".mb-4").style.display = "none";
        document.getElementsByName('PlanToSeparateBusiness')[0].disabled = true;
        document.getElementsByName('PlanToSeparateBusiness')[1].disabled = true;
        document.getElementsByName('PlanToSeparateBusiness')[0].required = false;
        var ele3 = document.getElementsByName("PlanToSeparateBusiness");
        for(var i=0;i<ele3.length;i++)
          ele3[i].checked = false;
        if(document.getElementsByName('PlanToSeparateBusiness')[0].closest('.d-flex').previousSibling.querySelector(".txt-red") !== null){
          document.getElementsByName('PlanToSeparateBusiness')[0].closest('.d-flex').previousSibling.querySelector(".txt-red").remove();
        }
        newData["PlanToSeparateBusiness"] = ""; */
      } else {
        document.getElementsByName('IsBusinessInList')[0].closest(".mb-4").style.display = "block";
        document.getElementsByName('IsBusinessInList')[0].disabled = false;
        document.getElementsByName('IsBusinessInList')[1].disabled = false;
        document.getElementsByName('IsBusinessInList')[0].required = true;
        document.getElementsByName('IsBusinessInList')[0].closest('.d-flex').previousSibling.innerHTML += '<span class="txt-red">*</span>';

       /*  document.getElementsByName('IsFarmingBusiness')[0].closest(".mb-4").style.display = "block";
        document.getElementsByName('IsFarmingBusiness')[0].disabled = false;
        document.getElementsByName('IsFarmingBusiness')[1].disabled = false;
        document.getElementsByName('PlanToSeparateBusiness')[0].closest(".mb-4").style.display = "block";
        document.getElementsByName('PlanToSeparateBusiness')[0].disabled = false;
        document.getElementsByName('PlanToSeparateBusiness')[1].disabled = false; */
      }
    }

    if(name==="IsBusinessInList"){
      if(value === 'Yes') {
        handleShowNotEligible();
      }
    }
    /* if(name==="PlanToSeparateBusiness"){
      if(value === 'Yes') {
        handleShowNotEligible();
      }
    }

    if(name==="IsFarmingBusiness"){
      if(value === 'Yes') {
        document.getElementsByName('PlanToSeparateBusiness')[0].disabled = false;
        document.getElementsByName('PlanToSeparateBusiness')[1].disabled = false;
        document.getElementsByName('PlanToSeparateBusiness')[0].required = true;
        document.getElementsByName('PlanToSeparateBusiness')[0].closest('.d-flex').previousSibling.innerHTML += '<span class="txt-red">*</span>';
      } else if(value === 'No') {
        document.getElementsByName('PlanToSeparateBusiness')[0].disabled = true;
        document.getElementsByName('PlanToSeparateBusiness')[1].disabled = true;
        document.getElementsByName('PlanToSeparateBusiness')[0].required = false;
        var ele3 = document.getElementsByName("PlanToSeparateBusiness");
        for(var i=0;i<ele3.length;i++)
          ele3[i].checked = false;
        if(document.getElementsByName('PlanToSeparateBusiness')[0].closest('.d-flex').previousSibling.querySelector(".txt-red") !== null){
          document.getElementsByName('PlanToSeparateBusiness')[0].closest('.d-flex').previousSibling.querySelector(".txt-red").remove();
        }
        newData["PlanToSeparateBusiness"] = "";
      }
    } */

    if(name==="TakenCredits"){
      if(value === 'Yes') {
        document.getElementsByName('CreditsTimeframeFrom')[0].disabled = false;
        document.getElementsByName('CreditsTimeframeFrom')[0].required = true;
        document.getElementsByName('CreditsTimeframeFrom')[0].previousSibling.innerHTML += '<span class="txt-red">*</span>';
        document.getElementsByName('CreditsTimeframeTo')[0].disabled = false;
        document.getElementsByName('CreditsTimeframeTo')[0].required = true;
        document.getElementsByName('CreditsTimeframeTo')[0].previousSibling.innerHTML += '<span class="txt-red">*</span>';
      } else if(value === 'No') {
        document.getElementsByName('CreditsTimeframeFrom')[0].disabled = true;
        document.getElementsByName('CreditsTimeframeFrom')[0].required = false;
        document.getElementsByName('CreditsTimeframeFrom')[0].value = "";
        if(document.getElementsByName('CreditsTimeframeFrom')[0].previousSibling.querySelector(".txt-red") !== null){
          document.getElementsByName('CreditsTimeframeFrom')[0].previousSibling.querySelector(".txt-red").remove();
        }
        newData["CreditsTimeframeFrom"] = "";

        document.getElementsByName('CreditsTimeframeTo')[0].disabled = true;
        document.getElementsByName('CreditsTimeframeTo')[0].required = false;
        document.getElementsByName('CreditsTimeframeTo')[0].value = "";
        if(document.getElementsByName('CreditsTimeframeTo')[0].previousSibling.querySelector(".txt-red") !== null){
          document.getElementsByName('CreditsTimeframeTo')[0].previousSibling.querySelector(".txt-red").remove();
        }
        newData["CreditsTimeframeTo"] = "";
      }
    }

    if(event.target.type === 'checkbox'){
      if(event.target.id.includes('Other')){
        if(event.target.checked){
          event.target.parentElement.closest('section').nextSibling.firstChild.classList.add('d-block');
          event.target.parentElement.closest('section').nextSibling.firstChild.classList.remove('d-none');
          event.target.parentElement.closest('section').nextSibling.getElementsByTagName('textarea')[0].required = true;
          event.target.parentElement.closest('section').nextSibling.getElementsByTagName('textarea')[0].placeholder = event.target.parentElement.closest('section').nextSibling.getElementsByTagName('textarea')[0].placeholder+'*';
        } else {
          event.target.parentElement.closest('section').nextSibling.firstChild.classList.add('d-none');
          event.target.parentElement.closest('section').nextSibling.firstChild.classList.remove('d-block');
          event.target.parentElement.closest('section').nextSibling.getElementsByTagName('textarea')[0].required = false;
          event.target.parentElement.closest('section').nextSibling.getElementsByTagName('textarea')[0].placeholder = event.target.parentElement.closest('section').nextSibling.getElementsByTagName('textarea')[0].placeholder.slice(0,-1);
          event.target.parentElement.closest('section').nextSibling.getElementsByTagName('textarea')[0].value = '';
          newData[event.target.parentElement.closest('section').nextSibling.getElementsByTagName('textarea')[0].name]='';
        }
      }

      if(value.slice(7)==="Disruption experienced"){
        let elChkBox = event.target.closest('.col').querySelectorAll('input[type="checkbox"]');
        let elDate = event.target.closest('.quarter-table').querySelectorAll('input[type="date"]');
        if(event.target.checked){
          for(let i = 0; i<elChkBox.length; i++){
            elChkBox[i].disabled = false;
          }
          for(let j = 0; j<elDate.length; j++){
            elDate[j].disabled = false;
            elDate[j].required = true;
            elDate[j].closest('.col').insertAdjacentHTML('beforeend', '<span class="txt-red">*</span>');
          }
          event.target.closest('.col').insertAdjacentHTML('beforeend', '<span class="txt-red">*</span>');
        } else {
          for(let i = 1; i<elChkBox.length; i++){
            elChkBox[i].disabled = true;
            elChkBox[i].checked = false;
          }
          for(let j = 0; j<elDate.length; j++){
            elDate[j].disabled = true;
            elDate[j].required = false;
            elDate[j].value = '';
            elDate[j].closest('.col').querySelector('.txt-red').remove();
            newData[elDate[j].name]='';
          }
          event.target.closest('.col').querySelector('.txt-red').remove();
        }
      }

      if(value.slice(7)==="Disrupted the entire quarter?"){
        let elDate = event.target.closest('.quarter-table').querySelectorAll('input[type="date"]');
        if(event.target.checked){
          elDate[0].value = elDate[0].min;
          elDate[1].value = elDate[0].max;
          for(let j = 0; j<elDate.length; j++){
            newData[elDate[j].name]=elDate[j].value;
          }
        } else {
          elDate[0].value = '';
          elDate[1].value = '';
          for(let j = 0; j<elDate.length; j++){
            newData[elDate[j].name]='';
          }
        }
      }

      if(name === "ClaimedCredits"){
        let elem = event.target.parentElement.closest('.form-check');
        let sibling = elem.parentNode.firstChild;
        // Loop through each sibling and push to the array
        while (sibling) {
          if (sibling.nodeType === 1 && sibling !== elem && sibling.classList[0] === 'form-check') {
            if(value === "None"){
              if(event.target.checked){
                sibling.closest('.form-check').querySelector('input[name="ClaimedCredits"]').checked = false;
                sibling.closest('.form-check').querySelector('input[name="ClaimedCredits"]').disabled = true;
              } else {
                sibling.closest('.form-check').querySelector('input[name="ClaimedCredits"]').disabled = false;
              }
            } else {
              if(event.target.checked){
                if(sibling.closest('.form-check').querySelector('input[value="None"]') != null){
                  sibling.closest('.form-check').querySelector('input[value="None"]').checked = false;
                }
              }
            }
          }
          sibling = sibling.nextSibling
        }
      }

      if(name === "Agreed" || name === "AcknowledgedData"){
        if(event.target.checked){
          event.target.value = 'true';
        } else {
          event.target.value = 'false';
        }
      }

      var checkboxes = document.getElementsByName(name);
      var checkboxesChecked = [];
      for (var i=0; i<checkboxes.length; i++) {
        // Push the checked ones onto an array...
        if (checkboxes[i].checked) {
          checkboxesChecked.push(checkboxes[i].value);
        }
      }
      console.log(checkboxesChecked);
      newData[name]=checkboxesChecked;
    } else {
      newData[name]=value;
    }

    console.log(newData);
    setInputs(newData);
    console.log('inputs', inputs);
  }

  const [showNotEligible, setShowNotEligible] = useState(false);
  const handleShowNotEligible = () => setShowNotEligible(true);
  const handleCloseNotEligible = () => setShowNotEligible(false);

  const handleChangeTimeframe = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    console.log(name);

    const newData={...inputsTimeframe};

    if(name.startsWith('DisruptionExperienced')){
      let el = event.target.closest('tr').querySelectorAll('.entire-quarter');
      let elDate = event.target.closest('tr').querySelectorAll('.disruption-date');
      if(value === "Yes"){
        for(let i=0; i<el.length; i++){
          el[i].querySelector('input[type="radio"]').disabled = false;
        }
      } else if(value === "No"){
        for(let i=0; i<el.length; i++){
          el[i].querySelector('input[type="radio"]').disabled = true;
          el[i].querySelector('input[type="radio"]').checked = false;
          newData[el[i].querySelector('input[type="radio"]').name]='';
        }
        for(let i=0; i<elDate.length; i++){
          elDate[i].disabled = true;
          elDate[i].value = '';
          newData[elDate[i].name]='';
        }
      }
    }
    if(name.startsWith('DisruptedEntireQuarter')){
      let el = event.target.closest('tr').querySelectorAll('.disruption-date');
      if(value === "No"){
        for(let i=0; i<el.length; i++){
          el[i].disabled = false;
          el[i].required = true;
        }
      } else if(value === "Yes"){
        for(let i=0; i<el.length; i++){
          el[i].disabled = true;
          el[i].required = false;
          el[i].value = '';
          newData[el[i].name]='';
        }
      }
    }
    if(name.startsWith('StartDate')){
      let el = event.target.closest('tr').querySelectorAll('.end-date');
      for(let i=0; i<el.length; i++){
        console.log(el[i]);
        el[i].value = '';
        el[i].min = value;
        newData[el[i].name]='';
      }
    }

    newData[name]=value;
    console.log(newData);
    console.log({...inputs, StaffingDisruptionData:newData});
    setInputsTimeframe(newData);
    setInputs({...inputs, StaffingDisruptionData:newData});
    console.log('inputs', inputs);
    console.log('inputsTimeframe', inputsTimeframe);
  }

  const handleDelete = (event) => {
    console.log('handleDelete');
    var key = event.keyCode || event.charCode;
    let custDataType = event.target.dataset.customDatatype;

    if( key == 8 || key == 46 ){
      if(event.target.value === ''){
        setInputs(values => ({...values, [event.target.name]: event.target.value}))
      }
    } else if(!(key >= 48 && key <=57) && key!==9 && key!==13 && custDataType === "amount"){
      event.preventDefault();
    }

    if(event.target.type==='number'){
      if(["e", "E", "+", "-"].includes(event.key)){
        event.preventDefault();
      }
    }
  }

  const [errorPopupText, setErrorPopupText] = useState(null);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const handleCloseErrorPopup = () => {
    setShowErrorPopup(false);
    setErrorPopupText(null);
  }
  const handleShowErrorPopup = (error_text) => {
    setErrorPopupText(error_text);
    setShowErrorPopup(true);
  }

  const handleUpload = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log(event.target.accept);
    //console.log(value);
    var extension = value.split('.').pop();
    //console.log(extension);

    if(event.target.accept === '.pdf'){
      if(extension=='pdf'){
        setIsLoading(true);
        console.log("File type matched");
        var file = document.querySelector('#'+event.target.id).files[0];
        console.log(file.size);
        let fileSizeInMB = file.size  / 1048576;
        console.log(fileSizeInMB);
  
        if(file !== undefined) {
          let filename = file.name;
          var fileData;
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function () {
            fileData = reader.result;
            //console.log(fileData);
            //newData[name]=fileData;
            //newData[name+'_filename']=filename;
            //console.log({token:token, entity_group:entityName, entity:name, file_name: filename, file_data: fileData});
            if(fileSizeInMB <= 10){
              axios.post(serverConfig.api_base_url + "set_file",{token:token, entity_group:entityName, entity:name, file_name: filename, file_data: fileData})
              .then(response=>{
                console.log('set_file response', response);
                if(response.data.status === 200) {
                  setIsLoading(false);
                  console.log('success');
                  getGroupData();//update to new filename
                  refreshSidebarCompletedStatus();
                  getPendingEntities();
                } else if(response.data.status === 301){
                  handleLogout();
                } else {
                  alert(response.data.message);
                  setIsLoading(false);
                }
              })
              .catch(err=>console.log("error is",err));
            } else {
              setIsLoading(false);
              handleShowErrorPopup('File size exceeds maximum limit of 10MB. Please try again.');
              event.target.value = '';
            }
          };
          reader.onerror = function (error) {
            console.log('Error: ', error);
          };
        }
      } else {
        alert("Only pdf file is allowed");
        event.target.value = null;
      }
    } else if(event.target.accept === '.pdf, .csv, .jpg, .png, .xls, .xlsx, .txt, .docx'){
      if(extension=='pdf' || extension=='csv' || extension=='jpg' || extension=='png' || extension=='xls' || extension=='xlsx' || extension=='txt' || extension=='docx'){
        setIsLoading(true);
        console.log("File type matched");
        var file = document.querySelector('#'+event.target.id).files[0];
        console.log(file.size);
        let fileSizeInMB = file.size  / 1048576;
        console.log(fileSizeInMB);

        if(file !== undefined) {
          let filename = file.name;
          var fileData;
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function () {
            fileData = reader.result;
            //console.log(fileData);
            //newData[name]=fileData;
            //newData[name+'_filename']=filename;
            //console.log({token:token, entity_group:entityName, entity:name, file_name: filename, file_data: fileData});
            if(fileSizeInMB <= 10){
              axios.post(serverConfig.api_base_url + "set_file",{token:token, entity_group:entityName, entity:name, file_name: filename, file_data: fileData})
              .then(response=>{
                console.log('set_file response', response);
                if(response.data.status === 200) {
                  setIsLoading(false);
                  console.log('success');
                  getGroupData();//update to new filename
                  refreshSidebarCompletedStatus();
                  getPendingEntities();
                } else if(response.data.status === 301){
                  handleLogout();
                } else {
                  alert(response.data.message);
                  setIsLoading(false);
                }
              })
              .catch(err=>console.log("error is",err));
            } else {
              setIsLoading(false);
              handleShowErrorPopup('File size exceeds maximum limit of 10MB. Please try again.');
              event.target.value = '';
            }
          };
          reader.onerror = function (error) {
            console.log('Error: ', error);
          };
        }
      } else {
        alert("Only pdf, csv, jpg, png, xls, xlsx, txt, docx files are allowed");
        event.target.value = null;
      }
    }
  }

  const submitFormData = (e) => {
    e.preventDefault();
    console.log(inputs);
    var formdata;
    if(document.querySelector('button[type="submit"]').textContent === 'Submit'){
      formdata = {token:token, entity_group:entityName, items:{Submitted: true}};
    } else if(document.querySelector('button[type="submit"]').textContent === 'Submit '){
      formdata = {token:token, entity_group:entityName, items:{OnboardingSubmitted: true}};
    }/*  else if(document.querySelector('button[type="submit"]').textContent === ' Submit'){
      formdata = {token:token, entity_group:entityName, items:{EngagementAgreementWSSubmitted: true}};
    } */ else {
      formdata = {token:token, entity_group:entityName, items:inputs};
    }
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "set_data",formdata)
    .then(response=>{
      console.log('set_data response', response);
      if(response.data.status === 200) {
        nextStep();
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  };

  const handleSaveFormData = () => {
    console.log(inputs);
    var formdata = {token:token, entity_group:entityName, items:inputs};
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "set_data",formdata)
    .then(response=>{
      console.log('set_data response', response);
      if(response.data.status === 200) {
        setShowAlertText("Data saved");
        setTimeout(() => {
          setShowAlertText(null);
        }, 5000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  };

  /* state wise disruption data - start */
  const handleChangeDisruptionData = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    console.log(disruptionData);
    console.log(...disruptionData);

    //const newData={...disruptionData};
    const newData={...disruptionDataInputs};

    if(event.target.type === 'textarea'){
      if(value !== ''){
        value = value.replace(/(\r\n|\r|\n)/g, '<br />').replace(/'/g, '&apos;');
      }
    }

    let stateEmpty = document.querySelector('#stateWiseDisruptionDataEmpty select[name="DisruptionState"]').value;
    let startDateEmpty = document.querySelector('#stateWiseDisruptionDataEmpty input[name="DisruptionStartDate"]').value;
    let endDateEmpty = document.querySelector('#stateWiseDisruptionDataEmpty input[name="DisruptionEndDate"]').value;
    let impactAtleastTenPercentEmpty = document.querySelector('#stateWiseDisruptionDataEmpty input[name="DisruptionImpactAtleastTenPercent"]:checked');
    let otherCircumstancesTexareaEmpty = document.querySelector('#stateWiseDisruptionDataEmpty textarea[name="DisruptionOtherCircumstances"]').value;
    console.log(stateEmpty, startDateEmpty, endDateEmpty, "--", impactAtleastTenPercentEmpty, otherCircumstancesTexareaEmpty);

    if(name === 'DisruptionStartDate'){
      //let slug = name.split('DisruptionStartDate').pop();
      event.target.closest('.row').querySelector('input[name="DisruptionEndDate"]').value = "";
      event.target.closest('.row').querySelector('input[name="DisruptionEndDate"]').min = value;
      newData["DisruptionEndDate"]="";
      newData["DisruptionQuarters"]="";
    } else if(name === 'DisruptionEndDate'){
      //let slug = name.split('DisruptionEndDate').pop();
      let startDate = event.target.closest('.row').querySelector('input[name="DisruptionStartDate"]').value;
      let endDate = event.target.closest('.row').querySelector('input[name="DisruptionEndDate"]').value;
      //console.log(startDate, endDate, startDate.split('-')[1]);
      
      // Ensure start is the earlier date;
      var sDate = new Date(startDate);
      var eDate = new Date(endDate);
      if (sDate > eDate) {
        var t = eDate;
        eDate = sDate;
        sDate = t;
      }

      // Copy input start date do don't affect original
      sDate = new Date(sDate);
      
      // Set to 2nd of month so adding months doesn't roll over and not affected by daylight saving
      sDate.setDate(2);

      // Initialise result array with start quarter
      var startQ = sDate.getFullYear() + 'Q' + Math.ceil((sDate.getMonth()+ 1)/3);
      var endQ   = eDate.getFullYear() + 'Q' + Math.ceil((eDate.getMonth()+ 1)/3);
      var result = [startQ];
      console.log(result);
      
      // List quarters from start to end
      while (startQ != endQ) {
        sDate.setMonth(sDate.getMonth() + 3);
        startQ = sDate.getFullYear() + 'Q' + Math.ceil((sDate.getMonth()+ 1)/3);
        result.push(startQ);
      } 
      
      console.log(result);
      newData["DisruptionQuarters"]=result;
    }

    if(event.target.type === 'checkbox'){
      if(event.target.value.includes('Other')){
        if(event.target.checked){
          event.target.parentElement.closest('.form-check').nextSibling.classList.add('d-block');
          event.target.parentElement.closest('.form-check').nextSibling.classList.remove('d-none');
          event.target.parentElement.closest('.form-check').nextSibling.getElementsByTagName('textarea')[0].required = true;
          event.target.parentElement.closest('.form-check').nextSibling.getElementsByTagName('textarea')[0].placeholder = event.target.parentElement.closest('.form-check').nextSibling.getElementsByTagName('textarea')[0].placeholder+'*';
          if(stateEmpty !== "" && startDateEmpty !=="" && endDateEmpty !=="" && otherCircumstancesTexareaEmpty !== ""){
            setAddNewDisabled(false);
          } else {
            setAddNewDisabled(true);
          }
        } else {
          event.target.parentElement.closest('.form-check').nextSibling.classList.add('d-none');
          event.target.parentElement.closest('.form-check').nextSibling.classList.remove('d-block');
          event.target.parentElement.closest('.form-check').nextSibling.getElementsByTagName('textarea')[0].required = false;
          event.target.parentElement.closest('.form-check').nextSibling.getElementsByTagName('textarea')[0].placeholder = event.target.parentElement.closest('.form-check').nextSibling.getElementsByTagName('textarea')[0].placeholder.slice(0,-1);
          event.target.parentElement.closest('.form-check').nextSibling.getElementsByTagName('textarea')[0].value = '';
          newData[event.target.parentElement.closest('.form-check').nextSibling.getElementsByTagName('textarea')[0].name]='';
          if(stateEmpty !== "" && startDateEmpty !=="" && endDateEmpty !==""){
            setAddNewDisabled(false);
          } else {
            setAddNewDisabled(true);
          }
        }
      }

      var checkboxes = event.target.closest('.options-wrapper').querySelectorAll('input[name="'+name+'"]');
      var checkboxesChecked = [];
      for (var i=0; i<checkboxes.length; i++) {
        // Push the checked ones onto an array...
        if (checkboxes[i].checked) {
          checkboxesChecked.push(checkboxes[i].value);
        }
      }
      console.log(checkboxesChecked);
      newData[name]=checkboxesChecked;
    } else {
      newData[name]=value;
    }

    console.log(inputs);

    if(stateEmpty !== "" && startDateEmpty !=="" && endDateEmpty !=="" && impactAtleastTenPercentEmpty !== null){
      if(document.querySelector('#stateWiseDisruptionDataEmpty textarea[name="DisruptionOtherCircumstances"]').hasAttribute('required')){
        if(otherCircumstancesTexareaEmpty !== ""){
          setAddNewDisabled(false);
          console.log(Date.now());
          newData["id"]=stateEmpty+startDateEmpty+endDateEmpty;
          newData["timestamp"]=Date.now();

          let newInputs = {StatewiseDisruptionData:[...disruptionData, newData]};
          console.log(newInputs);
          setInputs({StatewiseDisruptionData:[...disruptionData, newData]});
        } else {
          setAddNewDisabled(true);
        }
      } else {
        setAddNewDisabled(false);
        console.log(Date.now());
        newData["id"]=stateEmpty+startDateEmpty+endDateEmpty;
        newData["timestamp"]=Date.now();

        let newInputs = {StatewiseDisruptionData:[...disruptionData, newData]};
        console.log(newInputs);
        setInputs({StatewiseDisruptionData:[...disruptionData, newData]});
      }
    } else {
      setAddNewDisabled(true);
    }

    console.log(newData);
    console.log(inputs);
    setDisruptionDataInputs(newData);
  }

  const handleAddNew = () => {
    setDisruptionDataInputs({});
    console.log('disruptionData', disruptionData);
    console.log('disruptionDataInputs', disruptionDataInputs);
    console.log(disruptionData);
    setDisruptionData([...disruptionData, disruptionDataInputs]);
    setDisruptionData((stateDisruptionData) => {
      console.log(stateDisruptionData);
      var formdata = {token:token, entity_group:entityName, items:{StatewiseDisruptionData: stateDisruptionData}};
      console.log(formdata);
      setIsLoading(false);
      return stateDisruptionData;
    });
    
    document.querySelector('#stateWiseDisruptionDataEmpty select[name="DisruptionState"]').value = "";
    document.querySelector('#stateWiseDisruptionDataEmpty input[name="DisruptionStartDate"]').value = "";
    document.querySelector('#stateWiseDisruptionDataEmpty input[name="DisruptionEndDate"]').value = "";
    document.querySelector('#stateWiseDisruptionDataEmpty input[name="DisruptionEndDate"]').min = "2020-01-01";
    document.querySelector('#stateWiseDisruptionDataEmpty textarea[name="DisruptionComments"]').value = "";
    
    document.querySelector('#stateWiseDisruptionDataEmpty textarea[name="DisruptionOtherCircumstances"]').parentElement.classList.add('d-none');
    document.querySelector('#stateWiseDisruptionDataEmpty textarea[name="DisruptionOtherCircumstances"]').parentElement.classList.remove('d-block');
    document.querySelector('#stateWiseDisruptionDataEmpty textarea[name="DisruptionOtherCircumstances"]').required = false;
    document.querySelector('#stateWiseDisruptionDataEmpty textarea[name="DisruptionOtherCircumstances"]').placeholder = document.querySelector('#stateWiseDisruptionDataEmpty textarea[name="DisruptionOtherCircumstances"]').placeholder.slice(0,-1);
    document.querySelector('#stateWiseDisruptionDataEmpty textarea[name="DisruptionOtherCircumstances"]').value = "";

    let el = document.querySelectorAll('#stateWiseDisruptionDataEmpty input[name="DisruptionReducedServiceHoursReasons"]');
    for(let i=0; i<el.length; i++){
      el[i].checked = false;
    }
    let elRadio = document.querySelectorAll('#stateWiseDisruptionDataEmpty input[name="DisruptionImpactAtleastTenPercent"]');
    for(let j=0; j<elRadio.length; j++){
      elRadio[j].checked = false;
    }
    setAddNewDisabled(true);
  }

  const deleteDisruptionData = (itemid) => {
    setIsLoading(true);
    console.log(itemid);
    console.log(disruptionData);

    var array = disruptionData;
    var index = array.map(x => {
      return x.id;
    }).indexOf(itemid);
    
    array.splice(index, 1);
    console.log(array);
    console.log(inputs);
    setInputs(array);
    setDisruptionData(array);
    setDisruptionData((stateDisruptionData) => {
      console.log(stateDisruptionData);

      var formdata = {token:token, entity_group:entityName, items:{StatewiseDisruptionData: stateDisruptionData}};
      axios.post(serverConfig.api_base_url + "set_data",formdata)
      .then(response=>{
        console.log('set_data response', response);
        if(response.data.status === 200) {
          setIsLoading(false);
          console.log('success');
          setDisruptionData([]);
          getGroupData();//update to new data
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
          setIsLoading(false);
        }
      })
      .catch(err=>console.log("error is",err));
      return(stateDisruptionData);
    });
  }

  const [disruptionModalData, setDisruptionModalData] = useState({});
  const [showEditDisruptionData, setShowEditDisruptionData] = useState(false);
  const handleCloseEditDisruptionData = () => setShowEditDisruptionData(false);
  const handleShowEditDisruptionData = (itemid, itemDisruptionState, itemDisruptionStartDate, itemDisruptionEndDate, itemDisruptionImpactAtleastTenPercent, itemReducedServiceHoursReasons, itemOtherCircumstances, itemDisruptionComments, itemDisruptionQuarters) => {
    console.log(itemid);
    console.log(disruptionData);
    setDisruptionModalData({
      DisruptionDataId: itemid,
      DisruptionState: itemDisruptionState,
      DisruptionStartDate: itemDisruptionStartDate,
      DisruptionEndDate: itemDisruptionEndDate,
      DisruptionImpactAtleastTenPercent: itemDisruptionImpactAtleastTenPercent,
      DisruptionReducedServiceHoursReasons: itemReducedServiceHoursReasons,
      DisruptionOtherCircumstances: itemOtherCircumstances,
      DisruptionComments: itemDisruptionComments,
      DisruptionQuarters: itemDisruptionQuarters
    })

    setShowEditDisruptionData(true);
  }

  const handleEditDisruptionData = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    const newData={...disruptionModalData};
    console.log(newData);

    if(event.target.type === 'textarea'){
      if(value !== ''){
        value = value.replace(/(\r\n|\r|\n)/g, '<br />').replace(/'/g, '&apos;');
      }
    }

    if(name === 'DisruptionStartDate'){
      //let slug = name.split('DisruptionStartDate').pop();
      event.target.closest('.row').querySelector('input[name="DisruptionEndDate"]').value = "";
      event.target.closest('.row').querySelector('input[name="DisruptionEndDate"]').min = value;
      newData["DisruptionEndDate"]="";
      newData["DisruptionQuarters"]="";
    } else if(name === 'DisruptionEndDate'){
      //let slug = name.split('DisruptionEndDate').pop();
      let startDate = event.target.closest('.row').querySelector('input[name="DisruptionStartDate"]').value;
      let endDate = event.target.closest('.row').querySelector('input[name="DisruptionEndDate"]').value;
      //console.log(startDate, endDate, startDate.split('-')[1]);
      
      // Ensure start is the earlier date;
      var sDate = new Date(startDate);
      var eDate = new Date(endDate);
      if (sDate > eDate) {
        var t = eDate;
        eDate = sDate;
        sDate = t;
      }

      // Copy input start date do don't affect original
      sDate = new Date(sDate);
      
      // Set to 2nd of month so adding months doesn't roll over and not affected by daylight saving
      sDate.setDate(2);

      // Initialise result array with start quarter
      var startQ = sDate.getFullYear() + 'Q' + Math.ceil((sDate.getMonth()+ 1)/3);
      var endQ   = eDate.getFullYear() + 'Q' + Math.ceil((eDate.getMonth()+ 1)/3);
      var result = [startQ];
      console.log(result);
      
      // List quarters from start to end
      while (startQ != endQ) {
        sDate.setMonth(sDate.getMonth() + 3);
        startQ = sDate.getFullYear() + 'Q' + Math.ceil((sDate.getMonth()+ 1)/3);
        result.push(startQ);
      } 
      
      console.log(result);
      newData["DisruptionQuarters"]=result;
    }

    if(event.target.type === 'checkbox'){
      if(event.target.value.includes('Other')){
        if(event.target.checked){
          event.target.parentElement.closest('.form-check').nextSibling.classList.add('d-block');
          event.target.parentElement.closest('.form-check').nextSibling.classList.remove('d-none');
          event.target.parentElement.closest('.form-check').nextSibling.getElementsByTagName('textarea')[0].required = true;
          event.target.parentElement.closest('.form-check').nextSibling.getElementsByTagName('textarea')[0].placeholder = event.target.parentElement.closest('.form-check').nextSibling.getElementsByTagName('textarea')[0].placeholder+'*';
        } else {
          event.target.parentElement.closest('.form-check').nextSibling.classList.add('d-none');
          event.target.parentElement.closest('.form-check').nextSibling.classList.remove('d-block');
          event.target.parentElement.closest('.form-check').nextSibling.getElementsByTagName('textarea')[0].required = false;
          event.target.parentElement.closest('.form-check').nextSibling.getElementsByTagName('textarea')[0].placeholder = event.target.parentElement.closest('.form-check').nextSibling.getElementsByTagName('textarea')[0].placeholder.slice(0,-1);
          event.target.parentElement.closest('.form-check').nextSibling.getElementsByTagName('textarea')[0].value = '';
          newData[event.target.parentElement.closest('.form-check').nextSibling.getElementsByTagName('textarea')[0].name]='';
        }
      }

      var checkboxes = event.target.closest('.options-wrapper').querySelectorAll('input[name="'+name+'"]');
      var checkboxesChecked = [];
      for (var i=0; i<checkboxes.length; i++) {
        // Push the checked ones onto an array...
        if (checkboxes[i].checked) {
          checkboxesChecked.push(checkboxes[i].value);
        }
      }
      console.log(checkboxesChecked);
      newData[name]=checkboxesChecked;
    } else {
      newData[name]=value;
    }

    console.log(newData);
    setDisruptionModalData(newData);
  }

  const updateDisruptionData = (e, itemid) => {
    e.preventDefault();
    console.log(itemid);
    console.log(disruptionModalData);

    var array = disruptionData;

    for (const i of array) {
      if (i.id == itemid) {
        console.log(disruptionModalData.DisruptionComments);
        i.DisruptionState= disruptionModalData.DisruptionState;
        i.DisruptionStartDate= disruptionModalData.DisruptionStartDate;
        i.DisruptionEndDate= disruptionModalData.DisruptionEndDate;
        i.DisruptionImpactAtleastTenPercent= disruptionModalData.DisruptionImpactAtleastTenPercent;
        i.DisruptionReducedServiceHoursReasons= disruptionModalData.DisruptionReducedServiceHoursReasons;
        i.DisruptionOtherCircumstances= disruptionModalData.DisruptionOtherCircumstances;
        i.DisruptionComments= disruptionModalData.DisruptionComments;
        i.DisruptionQuarters= disruptionModalData.DisruptionQuarters;
      }
    }

    console.log(array);
    setDisruptionData(array);
    setDisruptionData((stateDisruptionData) => {
      console.log(stateDisruptionData);

      var formdata = {token:token, entity_group:entityName, items:{StatewiseDisruptionData: stateDisruptionData}};
      axios.post(serverConfig.api_base_url + "set_data",formdata)
      .then(response=>{
        console.log('set_data response', response);
        if(response.data.status === 200) {
          setIsLoading(false);
          console.log('success');
          setShowEditDisruptionData(false);
          setDisruptionData([]);
          getGroupData();//update to new data
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
          setIsLoading(false);
        }
      })
      .catch(err=>console.log("error is",err));
      return(stateDisruptionData);
    });
  }
  /* state wise disruption data - end */

  /* location wise disruption data - start */
  const handleChangeLocationDisruptionData = (event) => {
    const name = event.target.name;
    let value = event.target.value;
  
    console.log(disruptionData);
    console.log(...disruptionData);
  
    //const newData={...disruptionData};
    const newData={...disruptionDataInputs};
  
    if(event.target.type === 'textarea'){
      if(value !== ''){
        value = value.replace(/(\r\n|\r|\n)/g, '<br />').replace(/'/g, '&apos;');
      }
    }
  
    let locationEmpty = document.querySelector('#locationWiseDisruptionDataEmpty input[name="DisruptionLocation"]').value;
    let startDateEmpty = document.querySelector('#locationWiseDisruptionDataEmpty input[name="DisruptionLocStartDate"]').value;
    let endDateEmpty = document.querySelector('#locationWiseDisruptionDataEmpty input[name="DisruptionLocEndDate"]').value;
    console.log(locationEmpty, startDateEmpty, endDateEmpty);
  
    if(name === 'DisruptionLocStartDate'){
      //let slug = name.split('DisruptionLocStartDate').pop();
      event.target.closest('.row').querySelector('input[name="DisruptionLocEndDate"]').value = "";
      event.target.closest('.row').querySelector('input[name="DisruptionLocEndDate"]').min = value;
      newData["DisruptionLocEndDate"]="";
      newData["DisruptionQuarters"]="";
    } else if(name === 'DisruptionLocEndDate'){
      //let slug = name.split('DisruptionLocEndDate').pop();
      let startDate = event.target.closest('.row').querySelector('input[name="DisruptionLocStartDate"]').value;
      let endDate = event.target.closest('.row').querySelector('input[name="DisruptionLocEndDate"]').value;
      //console.log(startDate, endDate, startDate.split('-')[1]);
      
      // Ensure start is the earlier date;
      var sDate = new Date(startDate);
      var eDate = new Date(endDate);
      if (sDate > eDate) {
        var t = eDate;
        eDate = sDate;
        sDate = t;
      }
  
      // Copy input start date do don't affect original
      sDate = new Date(sDate);
      
      // Set to 2nd of month so adding months doesn't roll over and not affected by daylight saving
      sDate.setDate(2);
  
      // Initialise result array with start quarter
      var startQ = sDate.getFullYear() + 'Q' + Math.ceil((sDate.getMonth()+ 1)/3);
      var endQ   = eDate.getFullYear() + 'Q' + Math.ceil((eDate.getMonth()+ 1)/3);
      var result = [startQ];
      console.log(result);
      
      // List quarters from start to end
      while (startQ != endQ) {
        sDate.setMonth(sDate.getMonth() + 3);
        startQ = sDate.getFullYear() + 'Q' + Math.ceil((sDate.getMonth()+ 1)/3);
        result.push(startQ);
      } 
      
      console.log(result);
      newData["DisruptionQuarters"]=result;
    }
  
    newData[name]=value;
    console.log(inputs);
  
    if(locationEmpty !== "" && startDateEmpty !=="" && endDateEmpty !==""){
      setAddNewDisabled(false);
      console.log(Date.now());
      newData["id"]=locationEmpty+startDateEmpty+endDateEmpty;
      newData["timestamp"]=Date.now();
  
      let newInputs = {LocationwiseDisruptionData:[...disruptionData, newData]};
      console.log(newInputs);
      setInputs({...inputs, LocationwiseDisruptionData:[...disruptionData, newData]});
    } else {
      setAddNewDisabled(true);
    }
  
    console.log(newData);
    console.log(inputs);
    setDisruptionDataInputs(newData);
  }

  const handleAddNewLocation = () => {
    setDisruptionDataInputs({});
    console.log('disruptionData', disruptionData);
    console.log('disruptionDataInputs', disruptionDataInputs);
    console.log(disruptionData);
    setDisruptionData([...disruptionData, disruptionDataInputs]);
    setDisruptionData((stateDisruptionData) => {
      console.log(stateDisruptionData);
      var formdata = {token:token, entity_group:entityName, items:{LocationwiseDisruptionData: stateDisruptionData}};
      console.log(formdata);
      setIsLoading(false);
      return stateDisruptionData;
    });
    
    document.querySelector('#locationWiseDisruptionDataEmpty input[name="DisruptionLocation"]').value = "";
    document.querySelector('#locationWiseDisruptionDataEmpty input[name="DisruptionLocStartDate"]').value = "";
    document.querySelector('#locationWiseDisruptionDataEmpty input[name="DisruptionLocEndDate"]').value = "";
    document.querySelector('#locationWiseDisruptionDataEmpty input[name="DisruptionLocEndDate"]').min = "2020-01-01";
    document.querySelector('#locationWiseDisruptionDataEmpty textarea[name="DisruptionLocComments"]').value = "";
  
    setAddNewDisabled(true);
  }

  const deleteLocationDisruptionData = (itemid) => {
    setIsLoading(true);
    console.log(itemid);
    console.log(disruptionData);
  
    var array = disruptionData;
    var index = array.map(x => {
      return x.id;
    }).indexOf(itemid);
    
    array.splice(index, 1);
    console.log(array);
    console.log(inputs);
    setInputs({...inputs, LocationwiseDisruptionData:array});
    setDisruptionData(array);
    setDisruptionData((stateDisruptionData) => {
      console.log(stateDisruptionData);
  
      var formdata = {token:token, entity_group:entityName, items:{LocationwiseDisruptionData: stateDisruptionData}};
      axios.post(serverConfig.api_base_url + "set_data",formdata)
      .then(response=>{
        console.log('set_data response', response);
        if(response.data.status === 200) {
          setIsLoading(false);
          console.log('success');
          setDisruptionData([]);
          getGroupData();//update to new data
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
          setIsLoading(false);
        }
      })
      .catch(err=>console.log("error is",err));
      return(stateDisruptionData);
    });
  }

  const [locationDisruptionModalData, setLocationDisruptionModalData] = useState({});
  const [showEditLocationDisruptionData, setShowEditLocationDisruptionData] = useState(false);
  const handleCloseEditLocationDisruptionData = () => setShowEditLocationDisruptionData(false);
  const handleShowEditLocationDisruptionData = (itemid, itemDisruptionLocation, itemDisruptionStartDate, itemDisruptionEndDate, itemDisruptionComments, itemDisruptionQuarters) => {
    console.log(itemid);
    console.log(disruptionData);
    setLocationDisruptionModalData({
      DisruptionDataId: itemid,
      DisruptionLocation: itemDisruptionLocation,
      DisruptionStartDate: itemDisruptionStartDate,
      DisruptionEndDate: itemDisruptionEndDate,
      DisruptionComments: itemDisruptionComments,
      DisruptionQuarters: itemDisruptionQuarters
    })

    setShowEditLocationDisruptionData(true);
  }

  const handleEditLocationDisruptionData = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    const newData={...disruptionModalData};
    console.log(newData);

    if(event.target.type === 'textarea'){
      if(value !== ''){
        value = value.replace(/(\r\n|\r|\n)/g, '<br />').replace(/'/g, '&apos;');
      }
    }

    if(name === 'DisruptionLocStartDate'){
      //let slug = name.split('DisruptionLocStartDate').pop();
      event.target.closest('.row').querySelector('input[name="DisruptionLocEndDate"]').value = "";
      event.target.closest('.row').querySelector('input[name="DisruptionLocEndDate"]').min = value;
      newData["DisruptionLocEndDate"]="";
      newData["DisruptionQuarters"]="";
    } else if(name === 'DisruptionLocEndDate'){
      //let slug = name.split('DisruptionLocEndDate').pop();
      let startDate = event.target.closest('.row').querySelector('input[name="DisruptionLocStartDate"]').value;
      let endDate = event.target.closest('.row').querySelector('input[name="DisruptionLocEndDate"]').value;
      //console.log(startDate, endDate, startDate.split('-')[1]);
      
      // Ensure start is the earlier date;
      var sDate = new Date(startDate);
      var eDate = new Date(endDate);
      if (sDate > eDate) {
        var t = eDate;
        eDate = sDate;
        sDate = t;
      }

      // Copy input start date do don't affect original
      sDate = new Date(sDate);
      
      // Set to 2nd of month so adding months doesn't roll over and not affected by daylight saving
      sDate.setDate(2);

      // Initialise result array with start quarter
      var startQ = sDate.getFullYear() + 'Q' + Math.ceil((sDate.getMonth()+ 1)/3);
      var endQ   = eDate.getFullYear() + 'Q' + Math.ceil((eDate.getMonth()+ 1)/3);
      var result = [startQ];
      console.log(result);
      
      // List quarters from start to end
      while (startQ != endQ) {
        sDate.setMonth(sDate.getMonth() + 3);
        startQ = sDate.getFullYear() + 'Q' + Math.ceil((sDate.getMonth()+ 1)/3);
        result.push(startQ);
      } 
      
      console.log(result);
      newData["DisruptionQuarters"]=result;
    }

    newData[name]=value;
    console.log(newData);
    setLocationDisruptionModalData(newData);
  }

  const updateLocationDisruptionData = (e, itemid) => {
    e.preventDefault();
    console.log(itemid);
    console.log(locationDisruptionModalData);

    var array = disruptionData;

    for (const i of array) {
      if (i.id == itemid) {
        console.log(disruptionModalData.DisruptionComments);
        i.DisruptionLocation= disruptionModalData.DisruptionLocation;
        i.DisruptionStartDate= disruptionModalData.DisruptionStartDate;
        i.DisruptionEndDate= disruptionModalData.DisruptionEndDate;
        i.DisruptionImpactAtleastTenPercent= disruptionModalData.DisruptionImpactAtleastTenPercent;
        i.DisruptionReducedServiceHoursReasons= disruptionModalData.DisruptionReducedServiceHoursReasons;
        i.DisruptionOtherCircumstances= disruptionModalData.DisruptionOtherCircumstances;
        i.DisruptionComments= disruptionModalData.DisruptionComments;
        i.DisruptionQuarters= disruptionModalData.DisruptionQuarters;
      }
    }

    console.log(array);
    setDisruptionData(array);
    setDisruptionData((stateDisruptionData) => {
      console.log(stateDisruptionData);

      var formdata = {token:token, entity_group:entityName, items:{StatewiseDisruptionData: stateDisruptionData}};
      axios.post(serverConfig.api_base_url + "set_data",formdata)
      .then(response=>{
        console.log('set_data response', response);
        if(response.data.status === 200) {
          setIsLoading(false);
          console.log('success');
          setShowEditDisruptionData(false);
          setDisruptionData([]);
          getGroupData();//update to new data
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
          setIsLoading(false);
        }
      })
      .catch(err=>console.log("error is",err));
      return(stateDisruptionData);
    });
  }
  /* location wise disruption data - end */

  /* agreements and disclosures */
  const handleAgreeDisagree = (event) => {
    console.log(event.target.id);
    let targetid = event.target.id;
    if(event.target.checked){
      document.getElementById(targetid).checked = true;
      var formdata = {token:token, entity_group:entityName, items:{[`${targetid}`]: true}};
      console.log(formdata);
      axios.post(serverConfig.api_base_url + "set_data",formdata)
      .then(response=>{
        console.log(response);
        if(response.data.status === 200) {
          console.log('success');
          refreshSidebarCompletedStatus();
          getPendingEntities();
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    } else {
      document.getElementById(targetid).checked = false;
      var formdata = {token:token, entity_group:entityName, items:{[`${targetid}`]: ''}};
      console.log(formdata);
      axios.post(serverConfig.api_base_url + "set_data",formdata)
      .then(response=>{
        console.log(response);
        if(response.data.status === 200) {
          console.log('success');
          refreshSidebarCompletedStatus();
          getPendingEntities();
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }
  /* agreements and disclosures */

  const handleUploadFromTemplate = (event) => {
    setIsLoading(true);
    const name = event.target.name;
    const value = event.target.value;
    console.log(value);
    
    var file = document.querySelector('#'+event.target.id).files[0];
    console.log(file);

    var fileData;
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      fileData = reader.result;
      let fileDataWithoutPrefix = reader.result.split(',').pop();
      //console.log(fileData);
      //console.log(fileDataWithoutPrefix);

      axios.post(serverConfig.api_base_url + "upload_from_template",{token:token, template_name:'ventokredit_template', file_data: fileDataWithoutPrefix})
      .then(response=>{
        console.log('upload_from_template response', response);
        if(response.data.status === 200) {
          getGroupData();
          setIsLoading(false);
          console.log('success', JSON.parse(response.data.data));
        } else if(response.data.status >= 400 && response.data.status <= 499){
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const generateQRCode = () => {
    console.log(businessOwnerDetails);
    axios.post(serverConfig.api_base_url + "get_kyc_token",{token:token, entity_group: 'business_owner_id', entity_name: 'DriversLicense'})
    .then(response=>{
      console.log(response);
      if(response.data.status === 200) {
        console.log('success', window.location);
        let url = window.location.origin+"/kyc-validation?"+response.data.kyc_token;
        console.log(url);
        setVerifyIDUrl(url);
        //setShowQrCode(true);

        axios.post(serverConfig.api_base_url + "send_id_verification_link",{token:token, name: businessOwnerDetails.name, email_id: businessOwnerDetails.email, verification_url: url})
        .then(response=>{
          console.log(response);
          if(response.data.status === 200) {
            setQrCodesentMsg("ID verification link has been mailed to the Business Owner.");
            setTimeout(() => {
              setQrCodesentMsg(null);
            }, 5000);
          } else if(response.data.status === 301){
            handleLogout();
          } else {
            alert(response.data.message);
          }
        })
        .catch(err=>console.log("error is",err));
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const reducedServiceHrsReasonOptions = ["Full shutdown","Partial shutdown","Interrupted operations","Supply chain interruptions","Inability to access equipment","Limited capacity to operate","Inability to work with your vendors","Reduction in services or goods offered to your customers ","Cut down in your hours of operation","Shifting hours to increase sanitation of your facility","Employee absences due to quarantining requirements, exposures, or positive COVID cases","Other"];

  const[file, setFile] = useState('');
  const [showFileModal, setShowFileModal] = useState(false);
  const showFile = (entityname) => {
    console.log(entityname);
    setIsLoading(true);
    axios.post(serverConfig.api_base_url + "get_file",{token:token, entity:entityname})
    .then(response=>{
      if (response.data.status === 200) {
        setIsLoading(false);
        if(response.data.data !== null){
          setFile(response.data.data);
          setShowFileModal(true);
        } else {
          alert("File is missing");
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    } )
    .catch(err=>console.log("error is",err));
  }

  const downloadFile = (entityname, filename) => {
    console.log(entityname);
    setIsLoading(true);
    axios.post(serverConfig.api_base_url + "get_file",{token:token, entity:entityname})
    .then(response=>{
      if (response.data.status === 200) {
        setIsLoading(false);
        if(response.data.data !== null){
          alert('The File will now be downloaded into the folder that saves all your "Downloads".');
          FileSaver.saveAs(response.data.data, filename);
        } else {
          alert("File is missing");
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    } )
    .catch(err=>console.log("error is",err));
  }

  const[acknowledgeInitiated, setAcknowledgeInitiated] = useState(false);

  const handleAcknowledgeSubmit = (entity_name, event) => {
    console.log(event.target.parentElement.closest('div'));

    var allInputsFilled = true;
    let el = event.target.parentElement.closest('.acknowledge').querySelectorAll('input');
    for(let i = el.length - 1; i >= 0; i--){
      console.log(el[i].name, el[i].value);
      if(el[i].value === '' || el[i].value === 'on' || el[i].value === 'false'){//if input text/date value is empty or checkbox is not checked(i.e. is value is on/false)
        el[i].focus();
        allInputsFilled = false;
      }
    }
    console.log(inputs);
    if (allInputsFilled) {
      console.log(inputs);
      var formdata;
      formdata = {token:token, entity_group:entityName, items:{[entity_name] : {...inputs,Signed: true}}};
      console.log(formdata);
      if(authorizedSignerName === inputs.SignerName){
        axios.post(serverConfig.api_base_url + "set_data",formdata)
        .then(response=>{
          console.log('set_data response', response);
          if(response.data.status === 200) {
            getGroupData();
          } else if(response.data.status === 301){
            handleLogout();
          } else {
            alert(response.data.message);
          }
        })
        .catch(err=>console.log("error is",err));
      } else {
        handleShowErrorPopup('The name does not match with that of the Authorized Signer.');
      }
    }
  }

  const handleSetNav = (groupName) => {
    console.log(groupName);
    console.log(entityGroups);
    let stepNum = entityGroups.findIndex(x => x.label === groupName);
    //let stepName = entityGroups.findIndex(x => x.label === groupName);
    let stepName = entityGroups.filter(x => x.label === groupName)[0].group;
    console.log(stepNum, stepName);
    handleNav(stepNum, stepName);
  }

  const handleDownloadAll = () => {
    console.log('calling download all');
    setIsLoading(true);
    axios.post(serverConfig.api_base_url + "get_files_zip",{token:token, entity_group: 'final_form_941x'}, {
      responseType: 'arraybuffer'
    })
    .then(response=>{
      console.log('get_files_zip response', response);
      var blob = new Blob([response.data], {type: "octet/stream"});
      console.log(blob.size);
      FileSaver.saveAs(blob, 'final_form.zip');
      setIsLoading(false);
    } )
    .catch(err=>console.log("error is",err));
  }

  const handleDownload = (entityname, filename) => {
    axios.post(serverConfig.api_base_url + "get_file",{token:token, entity:entityname})
    .then(response=>{
      if (response.data.status === 200) {
        setIsLoading(false);
        if(response.data.data !== null){
          //setFile(response.data.data);
          //setShowFileModal(true);
          let url = response.data.data;
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          url = '';
        } else {
          alert("File is missing");
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    } )
    .catch(err=>console.log("error is",err));
  }

  return (
    <div className="position-relative">
      {isLoading && <div className="loading"><img src={require('./../assets/images/spinner.gif')} height="70" alt="Spinner" /></div>}
      <Form onSubmit={submitFormData}>
        <Container fluid>
          <fieldset disabled={groupData.editable==='1' ? false : true}>
          {groupData.group_title && <h1>{groupData.group_title}</h1>}
          {groupData.group_header && <div className="mb-4" dangerouslySetInnerHTML={{__html: groupData.group_header}}></div>}
          
          {entityName === 'gross_receipts' &&
            <div className="d-flex justify-content-between mt-4 mb-4">
              <div>
                <a href="/templates/ventokredit_template.xlsx" download className="txt-link">Download Gross Receipts Template</a>
                <OverlayTrigger placement="right" overlay={<Tooltip>Download this template to prepopulate Gross Receipts for a faster upload</Tooltip>}>
                  <span className="info"><i>i</i></span>
                </OverlayTrigger>
              </div>
              <div className="position-relative d-inline-block">
                <Form.Label htmlFor="UploadFromTemplate" className='upload upload-default txt-link'>Upload supporting documents</Form.Label>
                <Form.Control id="UploadFromTemplate" name="UploadFromTemplate" type="file" accept=".xlsx" onChange={handleUploadFromTemplate} />  
              </div>
            </div>
          }

          <div className={entityName === 'gross_receipts' ? "data-file-table rows-2" : entityName === 'expenses' && "data-file-table rows-3"}>
            {(entityName === 'gross_receipts' || entityName === 'expenses' || entityName === 'form_941' || entityName === 'form_941x'/*  || entityName === 'final_form_941x' */) &&
              <Row className="quarter-table-header">
                <Col><b>Calculation Year</b></Col>
                <Col><b>Quarter 1 (Jan - Mar)</b></Col>
                <Col><b>Quarter 2 (Apr - Jun)</b></Col>
                <Col><b>Quarter 3 (Jul - Sep)</b></Col>
                <Col><b>Quarter 4 (Oct - Dec)</b></Col>
              </Row>
            }
            
            {sectionData.length !== 0 &&
              sectionData.map((sectionItem, sectionIndex) => {
                var divClass;
                var minDate, maxDate;
                if(sectionItem.section_type === "address"){
                  divClass = 'address';
                  if(sectionItem.section_name === "mailing_address"){
                    if(showMailingAddr){
                      divClass = 'address d-flex';
                    } else {
                      divClass = 'address d-none';
                    }
                  }
                } else if(sectionItem.section_name === "other_circumstances"){
                  let reducedServiceHrsReason = JSON.parse(sectionData[1].section_entities[0].entity_data);
                  console.log(reducedServiceHrsReason);
                  if(reducedServiceHrsReason && reducedServiceHrsReason.includes('Other circumstances specific to your business')){
                    divClass = 'd-block';
                  } else {
                    divClass = 'd-none';
                  }
                } else if(sectionItem.section_type === "quarter_table"){
                  divClass = 'row quarter-table';
                  if(sectionItem.section_title === '2020 Q1'){
                    minDate = '2020-01-01';//yyyy-mm-dd
                    maxDate = '2020-03-31';//yyyy-mm-dd
                  } else if(sectionItem.section_title === '2020 Q2'){
                    minDate = '2020-04-01';//yyyy-mm-dd
                    maxDate = '2020-06-30';//yyyy-mm-dd
                  } else if(sectionItem.section_title === '2020 Q3'){
                    minDate = '2020-07-01';//yyyy-mm-dd
                    maxDate = '2020-09-30';//yyyy-mm-dd
                  } else if(sectionItem.section_title === '2020 Q4'){
                    minDate = '2020-10-01';//yyyy-mm-dd
                    maxDate = '2020-12-31';//yyyy-mm-dd
                  } else if(sectionItem.section_title === '2021 Q1'){
                    minDate = '2021-01-01';//yyyy-mm-dd
                    maxDate = '2021-03-31';//yyyy-mm-dd
                  } else if(sectionItem.section_title === '2021 Q2'){
                    minDate = '2021-04-01';//yyyy-mm-dd
                    maxDate = '2021-06-30';//yyyy-mm-dd
                  } else if(sectionItem.section_title === '2021 Q3'){
                    minDate = '2021-07-01';//yyyy-mm-dd
                    maxDate = '2021-09-30';//yyyy-mm-dd
                  } else if(sectionItem.section_title === '2021 Q4'){
                    minDate = '2021-10-01';//yyyy-mm-dd
                    maxDate = '2021-12-31';//yyyy-mm-dd
                  }
                } else if(sectionItem.section_type === "additional_documents"){
                  divClass = 'additional-docs';
                }
                return(<>
                {(entityName === 'covid19_info' && sectionItem.section_name === "disruption_period_2020_Q1") && <>
                  <h5 className="mb-3"><b>Periods of disruption</b><br/>Timeframes when the business experienced disruption as a result of one or more COVID-related government orders and indication if the business had at least a 10% negative impact compared to forecasts/expectations.</h5>
                  <Row className="quarter-table-header">
                    <Col><b>Quarters</b></Col>
                    <Col><b>Check as applicable</b></Col>
                    <Col><b>Starting Date</b></Col>
                    <Col><b>Ending Date</b></Col>
                  </Row>
                </>}
                
                {(entityName === 'gross_receipts_banks' && sectionItem.section_name === "gross_receipt_banks_data_2019") && <Row className="quarter-table-header">
                  <Col><b>Calculation Year</b></Col>
                  <Col><b>Quarter 1 (Jan - Mar)</b></Col>
                  <Col><b>Quarter 2 (Apr - Jun)</b></Col>
                  <Col><b>Quarter 3 (Jul - Sep)</b></Col>
                  <Col><b>Quarter 4 (Oct - Dec)</b></Col>
                </Row>}

                {(entityName === 'loan_origination' && sectionItem.section_name === "loan_origination_amount_2019") && <>
                  <h2 className="mt-5">Loan Origination Data (excluding PPP Loans)</h2>
                  <div className="mb-4"><h5>Use the table below to provide Loan Origination Amount (excluding PPP Loans).</h5></div>
                  <Row className="quarter-table-header">
                    <Col><b>Calculation Year</b></Col>
                    <Col><b>Quarter 1 (Jan - Mar)</b></Col>
                    <Col><b>Quarter 2 (Apr - Jun)</b></Col>
                    <Col><b>Quarter 3 (Jul - Sep)</b></Col>
                    <Col><b>Quarter 4 (Oct - Dec)</b></Col>
                  </Row>
                </>}
                {(entityName === 'loan_origination' && sectionItem.section_name === "ppp_loan_origination_amount_2019") && <>
                  <h2 className="mt-5">PPP Loan Origination Data</h2>
                  <div className="mb-4"><h5>Use the table below to provide PPP Loan Origination Data.</h5></div>
                  <Row className="quarter-table-header">
                    <Col><b>Calculation Year</b></Col>
                    <Col><b>Quarter 1 (Jan - Mar)</b></Col>
                    <Col><b>Quarter 2 (Apr - Jun)</b></Col>
                    <Col><b>Quarter 3 (Jul - Sep)</b></Col>
                    <Col><b>Quarter 4 (Oct - Dec)</b></Col>
                  </Row>
                </>}
                {(entityName === 'loan_origination' && sectionItem.section_name === "bank_account_opening_no_of_accounts_2019") && <>
                  <h2 className="mt-5">Bank Account Opening Data</h2>
                  <div className="mb-4"><h5>Use the table below to provide Bank Account Opening Information.</h5></div>
                  <Row className="quarter-table-header">
                    <Col><b>Calculation Year</b></Col>
                    <Col><b>Quarter 1 (Jan - Mar)</b></Col>
                    <Col><b>Quarter 2 (Apr - Jun)</b></Col>
                    <Col><b>Quarter 3 (Jul - Sep)</b></Col>
                    <Col><b>Quarter 4 (Oct - Dec)</b></Col>
                  </Row>
                </>}

                <section key={sectionIndex} className={(sectionItem.section_name === "customer_data_gross_receipts_2019" || sectionItem.section_name === "customer_data_gross_receipts_2020" || sectionItem.section_name === "customer_data_gross_receipts_2021") ? "w-25 pr-2 pb-5 mt-3 d-inline-block" : null}>

                  {sectionItem.section_name === "signed_final_form_941x" && <div className="mb-5"><Button variant="primary" type="button" onClick={() => handleDownloadAll('final_form_941x')}>Download{/*  All */}</Button></div>}

                  {(sectionIndex === 1 && (sectionItem.section_name === "customer_data_gross_receipts_2019" || sectionItem.section_name === "customer_data_gross_receipts_2020" || sectionItem.section_name === "customer_data_gross_receipts_2021")) && <p><b>Client provided gross receipts:</b></p>}
                  {(sectionItem.section_title && sectionItem.section_type!=='quarter_table') && <h2 className="mb-3" dangerouslySetInnerHTML={{__html: sectionItem.section_title}}></h2>}
                  {sectionItem.section_header && <div className="mb-4" dangerouslySetInnerHTML={{__html: sectionItem.section_header}}></div>}

                  {sectionItem.section_name === "mailing_address" &&
                    <Form.Group className="mb-4" controlId="diffMailAddr">
                      <Form.Check type="checkbox" label="Is different from above" onChange={handleDiffMailAddr} checked={mailingAddrChecked} />
                    </Form.Group>
                  }

                  {sectionItem.section_entities &&
                    <div className={divClass}>
                      {sectionItem.section_type==='quarter_table' && <Col>{sectionItem.section_title && <b>{sectionItem.section_title}</b>}</Col>}

                      {sectionItem.section_entities.sort(function(a,b){//sort as per sequence no. and then map
                        return a.entity_seq_no - b.entity_seq_no;
                      }).map((entityItem, entityIndex) => {
                        return(<>
                          {sectionItem.section_type === 'statement_of_fact' ? <>
                            {entityItem.entity_name === "CdPPP1Period" && <div className="mt-3"><b>PPP loan covered period (if applicable):</b></div>}
                            {entityItem.entity_name === "TpEligibilityNotesGR" && <div className="mt-3"><b>Eligibility notes:</b></div>}
                            <Row key={entityIndex} className={entityItem.entity_data_type.type === "longtext" ? (entityItem.entity_data_type.is_sub_section === 1 ? "pl-3 mb-2" : "mb-3") : "mb-1"}>
                              {entityItem.entity_label && <>
                                {entityItem.entity_data_type.is_bullet_point === 1 && <Col xs="auto" className="pr-0">&#x2022;</Col>}
                                <Col xs={entityItem.entity_data_type.type === "longtext" ? "12" : "auto"}>
                                  <label className="mb-0">
                                    <b>{entityItem.entity_label}:</b> {(entityItem.entity_mandatory === 1 || sectionItem.section_name === "mailing_address") && <span className='txt-red'>*</span>}
                                    {entityItem.entity_info &&
                                      <OverlayTrigger placement="right" overlay={<Tooltip><span dangerouslySetInnerHTML={{__html: entityItem.entity_info}} /></Tooltip>}>
                                        <span className="info"><i>i</i></span>
                                      </OverlayTrigger>
                                    }
                                  </label>
                                </Col>
                                <Col className={entityItem.entity_data_type.type === "longtext" ? null : "pl-0"}>
                                  {(entityItem.entity_data_type.type === "amount" && entityItem.entity_data) && <span>$</span>}
                                  <span dangerouslySetInnerHTML={{__html: entityItem.entity_data}} />
                                </Col>
                              </>}
                            </Row>
                            </> :
                            <Form.Group key={entityIndex} className={sectionItem.section_type==='quarter_table' ? 'col' : 'mb-4'}>
                            {//type = text
                              (entityItem.entity_data_type.type === 'text' && entityItem.entity_name !== 'Submitted') && <>
                                {entityItem.entity_label && <>
                                  <Form.Label>
                                    <span dangerouslySetInnerHTML={{__html: entityItem.entity_label}} /> {(entityItem.entity_mandatory === 1 || sectionItem.section_name === "mailing_address") && <span className='txt-red'>*</span>}
                                    {entityItem.entity_info &&
                                      <OverlayTrigger placement="right" overlay={<Tooltip><span dangerouslySetInnerHTML={{__html: entityItem.entity_info}} /></Tooltip>}>
                                        <span className="info"><i>i</i></span>
                                      </OverlayTrigger>
                                    }
                                  </Form.Label>
                                </>}
                                <Form.Control name={entityItem.entity_name} defaultValue={(inputs[entityItem.entity_name] && inputs[entityItem.entity_name].replace(/&apos;/g,"'")) || (entityItem.entity_data && entityItem.entity_data.replace(/&apos;/g,"'"))} onChange={handleChange} onKeyDown={handleDelete} type="text" disabled={sectionItem.section_name === "mailing_address" && isMailAddrDisabled} required={sectionItem.section_name === "mailing_address" && isMailAddrRequired} />
                              </>
                            }
                            {//type = longtext
                              entityItem.entity_data_type.type === 'longtext' && <>
                                {entityItem.entity_label &&
                                  <Form.Label>
                                    <span dangerouslySetInnerHTML={{__html: entityItem.entity_label}} /> {(entityItem.entity_mandatory === 1 || sectionItem.section_name === "mailing_address") && <span className='txt-red'>*</span>}
                                    {entityItem.entity_info &&
                                      <OverlayTrigger placement="right" overlay={<Tooltip><span dangerouslySetInnerHTML={{__html: entityItem.entity_info}} /></Tooltip>}>
                                        <span className="info"><i>i</i></span>
                                      </OverlayTrigger>
                                    }
                                  </Form.Label>
                                }

                                <Form.Control as="textarea" id={entityItem.entity_name} name={entityItem.entity_name} defaultValue={(inputs[entityItem.entity_name] && inputs[entityItem.entity_name].replace(/<br\s*\/?>/gi,'\n').replace(/&apos;/g,"'")) || (entityItem.entity_data && entityItem.entity_data.replace(/<br\s*\/?>/gi,'\n').replace(/&apos;/g,"'"))} placeholder={'Type here...'} onChange={handleChange} onKeyDown={handleDelete} style={{ height: '100px' }} disabled={entityItem.entity_data_type.is_disabled===1} />
                              </>
                            }
                            {//type = displayText
                              entityItem.entity_data_type.type === 'displayText' && <>
                                {entityItem.entity_label && <>
                                  <div className="d-flex">
                                    <span dangerouslySetInnerHTML={{__html: entityItem.entity_label}} /> {entityItem.entity_mandatory === 1 && <span className='txt-red'>*</span>}
                                    {entityItem.entity_info &&
                                      <OverlayTrigger placement="right" overlay={<Tooltip><span dangerouslySetInnerHTML={{__html: entityItem.entity_info}} /></Tooltip>}>
                                        <span className="info"><i>i</i></span>
                                      </OverlayTrigger>
                                    }
                                  </div>
                                </>}
                              </>
                            }
                            {//type = number
                              entityItem.entity_data_type.type === 'number' && <>
                                {entityItem.entity_label && <>
                                  <Form.Label>
                                    <span dangerouslySetInnerHTML={{__html: entityItem.entity_label}} /> {entityItem.entity_mandatory === 1 && <span className='txt-red'>*</span>}
                                    {entityItem.entity_info &&
                                      <OverlayTrigger placement="right" overlay={<Tooltip><span dangerouslySetInnerHTML={{__html: entityItem.entity_info}} /></Tooltip>}>
                                        <span className="info"><i>i</i></span>
                                      </OverlayTrigger>
                                    }
                                  </Form.Label>
                                </>}
                                <Form.Control name={entityItem.entity_name} placeholder={entityItem.entity_mandatory === 1 ? (entityItem.entity_data_type.placeholder ? entityItem.entity_data_type.placeholder+" *" : 'Enter Number here *') : (entityItem.entity_data_type.placeholder ? entityItem.entity_data_type.placeholder : 'Enter Number here')} defaultValue={inputs[entityItem.entity_name] || entityItem.entity_data} onChange={handleChange} onKeyDown={handleDelete} type="number" min="0"/>
                              </>
                            }
                            {//type = phone_number
                              entityItem.entity_data_type.type === 'phone_number' && <>
                                {entityItem.entity_label && <>
                                  <Form.Label>
                                    <span dangerouslySetInnerHTML={{__html: entityItem.entity_label}} /> {entityItem.entity_mandatory === 1 && <span className='txt-red'>*</span>}
                                    {entityItem.entity_info &&
                                      <OverlayTrigger placement="right" overlay={<Tooltip><span dangerouslySetInnerHTML={{__html: entityItem.entity_info}} /></Tooltip>}>
                                        <span className="info"><i>i</i></span>
                                      </OverlayTrigger>
                                    }
                                  </Form.Label>
                                </>}
                                <Form.Control name={entityItem.entity_name} defaultValue={inputs[entityItem.entity_name] || entityItem.entity_data} onChange={handleChange} onKeyDown={handleDelete} type="text" pattern="[-0-9+() ]{1,}$" />
                              </>
                            }
                            {//type = amount
                              entityItem.entity_data_type.type === 'amount' && <>
                                {entityItem.entity_label && <>
                                  <Form.Label>
                                    <span dangerouslySetInnerHTML={{__html: entityItem.entity_label}} /> {entityItem.entity_mandatory === 1 && <span className='txt-red'>*</span>}
                                    {entityItem.entity_info &&
                                      <OverlayTrigger placement="right" overlay={<Tooltip><span dangerouslySetInnerHTML={{__html: entityItem.entity_info}} /></Tooltip>}>
                                        <span className="info"><i>i</i></span>
                                      </OverlayTrigger>
                                    }
                                  </Form.Label>
                                </>}
                                <Form.Control name={entityItem.entity_name} defaultValue={inputs[entityItem.entity_name] || entityItem.entity_data} onChange={handleChange} onKeyDown={handleDelete} type="text" data-custom-datatype="amount" placeholder={entityItem.entity_mandatory === 1 ? "USD $ 12233*" : "USD $ 12233"} />{entityItem.entity_mandatory === 1 && <span className='txt-red placement-right'>*</span>}
                              </>
                            }
                            {//type = email
                              entityItem.entity_data_type.type === 'email' && <>
                                {entityItem.entity_label && <>
                                  <Form.Label>
                                    <span dangerouslySetInnerHTML={{__html: entityItem.entity_label}} /> {entityItem.entity_mandatory === 1 && <span className='txt-red'>*</span>}
                                    {entityItem.entity_info &&
                                      <OverlayTrigger placement="right" overlay={<Tooltip><span dangerouslySetInnerHTML={{__html: entityItem.entity_info}} /></Tooltip>}>
                                        <span className="info"><i>i</i></span>
                                      </OverlayTrigger>
                                    }
                                  </Form.Label>
                                </>}
                                <Form.Control name={entityItem.entity_name} defaultValue={inputs[entityItem.entity_name] || entityItem.entity_data} onChange={handleChange} onKeyDown={handleDelete} type="email" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z]{2,}$" />
                              </>
                            }
                            {//type = url
                              entityItem.entity_data_type.type === 'url' && <>
                                {entityItem.entity_label && <>
                                  <Form.Label>
                                    <span dangerouslySetInnerHTML={{__html: entityItem.entity_label}} /> {entityItem.entity_mandatory === 1 && <span className='txt-red'>*</span>}
                                    {entityItem.entity_info &&
                                      <OverlayTrigger placement="right" overlay={<Tooltip><span dangerouslySetInnerHTML={{__html: entityItem.entity_info}} /></Tooltip>}>
                                        <span className="info"><i>i</i></span>
                                      </OverlayTrigger>
                                    }
                                  </Form.Label>
                                </>}
                                <Form.Control name={entityItem.entity_name} defaultValue={inputs[entityItem.entity_name] || entityItem.entity_data} onChange={handleChange} onKeyDown={handleDelete} type="text" pattern="(https?:\/\/|)([A-Za-z0-9-]+\.)+[A-Za-z]{2,20}" />
                              </>
                            }
                            {//type = radio
                              entityItem.entity_data_type.type === 'radio' && <>
                                {entityItem.entity_label && <>
                                  <Form.Label>
                                    <span dangerouslySetInnerHTML={{__html: entityItem.entity_label}} /> {entityItem.entity_mandatory === 1 && <span className='txt-red'>*</span>}
                                    {entityItem.entity_info &&
                                      <OverlayTrigger placement="right" overlay={<Tooltip><span dangerouslySetInnerHTML={{__html: entityItem.entity_info}} /></Tooltip>}>
                                        <span className="info"><i>i</i></span>
                                      </OverlayTrigger>
                                    }
                                  </Form.Label>
                                </>}
                                <div className="d-flex flex-wrap">
                                  {entityItem.entity_data_type.options.map((options, optionsIndex) =>
                                    <Form.Check key={optionsIndex} inline type="radio" name={entityItem.entity_name} value={options || ""} onChange={handleChange} id={entityItem.entity_name + options} label={options} defaultChecked={entityItem.entity_data === options} disabled={entityItem.entity_data_type.is_disabled===1} />
                                  )}
                                </div>
                              </>
                            }
                            {//type = lookup
                              entityItem.entity_data_type.type === 'lookup' && <>
                                {entityItem.entity_label && <>
                                  <Form.Label className="mr-3">
                                    <span dangerouslySetInnerHTML={{__html: entityItem.entity_label}} /> {(entityItem.entity_mandatory === 1 || sectionItem.section_name === "mailing_address") && <span className='txt-red'>*</span>}
                                    {entityItem.entity_info &&
                                      <OverlayTrigger placement="right" overlay={<Tooltip><span dangerouslySetInnerHTML={{__html: entityItem.entity_info}} /></Tooltip>}>
                                        <span className="info"><i>i</i></span>
                                      </OverlayTrigger>
                                    }
                                  </Form.Label>
                                </>}

                                {entityItem.entity_data_type.key === 'state' ?
                                  <Form.Select name={entityItem.entity_name} defaultValue={entityItem.entity_data || ""} onChange={handleChange} id={entityItem.entity_name} disabled={sectionItem.section_name === "mailing_address" && isMailAddrDisabled} required={sectionItem.section_name === "mailing_address" && isMailAddrRequired}>
                                    <option value="">Select</option>
                                    {stateLookupList.map(state => <option value={state}>{state}</option>)}
                                  </Form.Select>
                                  :
                                  <Form.Select name={entityItem.entity_name} defaultValue={entityItem.entity_data || ""} onChange={handleChange} id={entityItem.entity_name}>
                                    <option value="">Select</option>
                                    {entityItem.entity_data_type.options.map((options, optionsIndex) =>
                                      <option key={optionsIndex} value={options}>{options}</option>
                                    )}
                                  </Form.Select>
                                }
                              </>
                            }
                            {//type = date
                              entityItem.entity_data_type.type === 'date' && <>
                                {entityItem.entity_label && <>
                                  <Form.Label className="mr-3">
                                    <span dangerouslySetInnerHTML={{__html: entityItem.entity_label}} /> {entityItem.entity_mandatory === 1 && <span className='txt-red'>*</span>}
                                    {entityItem.entity_info &&
                                      <OverlayTrigger placement="right" overlay={<Tooltip><span dangerouslySetInnerHTML={{__html: entityItem.entity_info}} /></Tooltip>}>
                                        <span className="info"><i>i</i></span>
                                      </OverlayTrigger>
                                    }
                                  </Form.Label>
                                </>}
                                {sectionItem.section_type === 'quarter_table' ?
                                  <><Form.Control name={entityItem.entity_name} defaultValue={inputs[entityItem.entity_name] || entityItem.entity_data} onChange={handleChange} onKeyDown={handleDelete} type="date" disabled={!entityItem.entity_data} required={entityItem.entity_data} min={minDate} max={maxDate} />
                                  {entityItem.entity_data && <span className='txt-red'>*</span>}</>
                                  :
                                  <><Form.Control name={entityItem.entity_name} defaultValue={inputs[entityItem.entity_name] || entityItem.entity_data} onChange={handleChange} onKeyDown={handleDelete} type="date" />
                                  {!entityItem.entity_label && entityItem.entity_mandatory === 1 && <span className='txt-red'>*</span>}</>
                                }
                                
                              </>
                            }
                            {//type = multiselect
                              entityItem.entity_data_type.type === 'multiselect' && <>
                                {entityItem.entity_label && <>
                                  <Form.Label><h5>
                                    <span dangerouslySetInnerHTML={{__html: entityItem.entity_label}} /> {entityItem.entity_mandatory === 1 && <span className='txt-red'>*</span>}
                                    {entityItem.entity_info &&
                                      <OverlayTrigger placement="right" overlay={<Tooltip><span dangerouslySetInnerHTML={{__html: entityItem.entity_info}} /></Tooltip>}>
                                        <span className="info"><i>i</i></span>
                                      </OverlayTrigger>
                                    }
                                  </h5></Form.Label>
                                </>}
                                {sectionItem.section_type === 'quarter_checkbox' ?
                                  <>{entityItem.entity_data_type.options.map((options, optionsIndex) =>
                                    <Col><Form.Check key={optionsIndex} type="checkbox" name={entityItem.entity_name} value={options} onChange={handleChange} id={options} label={options.slice(5).replace("Q", "Quarter ")} defaultChecked={entityItem.entity_data && entityItem.entity_data.includes(options)} /></Col>
                                    ).reduce(function(r, element, index) {
                                      // create element groups with size 3, result looks like:
                                      // [[elem1, elem2, elem3], [elem4, elem5, elem6], ...]
                                      index % 4 === 0 && r.push([]);
                                      r[r.length - 1].push(element);
                                      return r;
                                    }, []).map(function(rowContent,i) {
                                      // surround every group with 'row'
                                      //console.log(rowContent);
                                      return <Row><Col><b>{rowContent[0].props.children.props.value.slice(0,-3)}</b></Col>{rowContent}</Row>;
                                    }
                                  )}</> : <>{sectionItem.section_type === 'quarter_table' ?
                                    <>{entityItem.entity_data_type.options && entityItem.entity_data_type.options.map((options, optionsIndex) => {
                                      var disruptionExperiencedCheck;
                                      disruptionExperiencedCheck = entityItem.entity_data ? 1 : 0;

                                      return(<>{/* aaa-{disruptionExperiencedCheck}, {!disruptionExperiencedCheck}, {optionsIndex} */}
                                      <Form.Check key={optionsIndex} type="checkbox" name={entityItem.entity_name} value={options} onChange={handleChange} id={options} label={options.slice(7)} defaultChecked={entityItem.entity_data && entityItem.entity_data.includes(options)} disabled={disruptionExperiencedCheck === 1 ? 0 : optionsIndex} /></>)}
                                    )}
                                    {entityItem.entity_data && <span className='txt-red'>*</span>}</>
                                    :
                                    <>{entityItem.entity_data_type.options && entityItem.entity_data_type.options.map((options, optionsIndex) =>
                                      <Form.Check key={optionsIndex} type="checkbox" name={entityItem.entity_name} value={options} onChange={handleChange} id={options} label={options} defaultChecked={entityItem.entity_data && entityItem.entity_data.includes(options)} />
                                    )}</>
                                  }</>
                                }
                                {!entityItem.entity_label && entityItem.entity_mandatory === 1 && <span className='txt-red'>*</span>}
                              </>
                            }
                            {//type = file
                              entityItem.entity_data_type.type === 'file' && <>
                                {entityItem.entity_data_type.is_downloadable === 1 ? <>
                                  {entityItem.entity_data && <>
                                    {/* {entityItem.entity_data.split(".")[1] === 'docx' ?
                                      <div className="d-inline-flex align-items-center view-file" onClick={() => downloadFile(entityItem.entity_name, entityItem.entity_data)}><span className="mr-2" title={entityItem.entity_data}><i className="fas fa-file-download" style={{fontSize: 25}}></i></span><span className="txt-overflow">{entityItem.entity_data}</span></div>
                                      :
                                      <div className="d-inline-flex align-items-center view-file" onClick={() => showFile(entityItem.entity_name)}><span className="mr-2" title={entityItem.entity_data}><img src={require('./../assets/images/file-icon.png')} width={entityItem.entity_data_type.icon_size === 'large' ? '40' : '25'} /></span><span className="txt-overflow">{entityItem.entity_data}</span></div>
                                    } */}
                                    <div className="d-inline-flex align-items-center">
                                      <div className="view-file mr-2" onClick={() => showFile(entityItem.entity_name)} title={entityItem.entity_data}><img src={require('./../assets/images/file-icon.png')} width={entityItem.entity_data_type.icon_size === 'large' ? '40' : '25'} /></div>
                                      <div className="view-file mr-2" onClick={() => downloadFile(entityItem.entity_name, entityItem.entity_data)} title={entityItem.entity_data}><i className="fas fa-file-download" style={{fontSize: 28}}></i></div>
                                      <span className="txt-overflow">{entityItem.entity_data}</span>
                                    </div>
                                  </>}
                                </>
                                :
                                (entityItem.entity_data_type.is_viewable === 1 ? <>
                                  {entityItem.entity_data ? <>
                                      <div style={{maxWidth: "max-content"}}><div className="text-center"><div className='txt-green'>&#x2714;</div><span className="txt-overflow">{entityItem.entity_data}</span></div></div>
                                      {entityItem.entity_data_type.is_single_download === 1 && <div className="mt-4 mb-5"><span className="btn btn-primary" onClick={() => handleDownload(entityItem.entity_name,entityItem.entity_data)}>Download</span></div>}
                                  </> : <>&nbsp;</>}
                                </> :
                                <div className="position-relative">
                                  {sectionItem.section_type === 'quarter_table' && <>
                                    <Form.Label htmlFor={entityItem.entity_name} className='upload upload-default'>
                                      <span className="txt-upload">Upload {entityItem.entity_mandatory === 1 && <span className='txt-red'>*</span>}</span>
                                      {entityItem.entity_data ? entityItem.entity_data : <span className="txt-gray">[Max 10MB]</span>}
                                    </Form.Label>
                                    <Form.Control id={entityItem.entity_name} name={entityItem.entity_name} type="file" accept=".pdf, .csv, .jpg, .png, .xls, .xlsx, .txt, .docx" onChange={handleUpload} />
                                  </>}
                                  {sectionItem.section_type === 'default' && <>
                                    {entityItem.entity_data_type.template_name && <a href={"/templates/"+entityItem.entity_data_type.template_name+".pdf"} download className="txt-link template-link">Form download</a>}
                                     <Form.Label htmlFor={entityItem.entity_name} className='upload'>{entityItem.entity_data ? entityItem.entity_data : <><img src={require('./../assets/images/upload-icon.png')} alt="upload" className="mr-3" />Drag and Drop the file here or <u>Browse</u>. [Max 10MB] {entityItem.entity_mandatory === 1 && <span className='txt-red'>*</span>}</>}</Form.Label>
                                     <Form.Control id={entityItem.entity_name} name={entityItem.entity_name} type="file" accept={entityItem.entity_data_type.is_pdf === 1 ? ".pdf" : ".pdf, .csv, .jpg, .png, .xls, .xlsx, .txt, .docx"} onChange={handleUpload} disabled={entityItem.entity_data_type.is_disabled===1} />
                                  </>}
                                  {sectionItem.section_type === 'additional_documents' && <>
                                    {entityItem.entity_data ? <>{entityItem.entity_data} {entityItem.entity_updated_at && <><br/><small>{entityItem.entity_updated_at}</small></>}</> : <>
                                      <Form.Label htmlFor={entityItem.entity_name} className='upload upload-default'>
                                        <span className="txt-upload">Upload New File {entityItem.entity_mandatory === 1 && <span className='txt-red'>*</span>}</span>
                                        {entityItem.entity_data ? entityItem.entity_data : <span className="txt-gray">[Max 10MB]</span>}
                                      </Form.Label>
                                      <Form.Control id={entityItem.entity_name} name={entityItem.entity_name} type="file" accept=".pdf, .csv, .jpg, .png, .xls, .xlsx, .txt, .docx" onChange={handleUpload} />
                                    </>}
                                  </>}
                                </div>)
                                }
                              </>
                            }
                            {//type = boolean
                              entityItem.entity_data_type.type === 'boolean' && <>
                                <div className={formSubmitted ? 'is-read-only agreements-check' : 'agreements-check'}><Form.Check type="checkbox" id={entityItem.entity_name} defaultChecked={entityItem.entity_data} label={<><span dangerouslySetInnerHTML={{__html: entityItem.entity_label}} /> {entityItem.entity_mandatory === 1 && <span className='txt-red'>*</span>}</>} required={entityItem.entity_data_type.is_required === 0 ? false : (entityItem.entity_mandatory === 1)} onChange={handleAgreeDisagree} /></div>
                              </>
                            }
                            {//type = json
                              entityItem.entity_data_type.type === 'json' && <>
                                {entityItem.entity_data_type.verify_id === 1 && <>
                                  {entityItem.entity_data ? <p><img src={require('./../assets/images/tick-star.png')} alt="completed" className="mr-2" />ID verification documents submitted</p>
                                  : <div>
                                    {/* {!showQrCode && <Button variant="gray-dashed" onClick={generateQRCode}>Click here to send ID verification link to the business owner</Button>}
                                    {showQrCode && <div className="qrcode">
                                      <div className="qrcode-image"><QRCode value={verifyIDUrl} size="200" /></div>
                                      <p>To continue with the ID verification you would need to scan the above QR code and please keep your drivers License handy</p>
                                    </div>} */}
                                    {/* {document.querySelector('input[name="BusinessOwnerFullName"]').value} */}
                                    {showQrCode ? <div className="qrcode">
                                      <div className="qrcode-image"><QRCode value={verifyIDUrl} size="200" /></div>
                                      <p>To continue with the ID verification you would need to scan the above QR code and please keep your drivers License handy</p>
                                    </div> : 
                                    <>{sendLinkDisabled && <p className="txt-red">Business owner details not provided. Visit this tab after filling in business owner details.</p>}<Button variant="gray-dashed" onClick={generateQRCode} disabled={sendLinkDisabled}>Click here to send ID verification link to the business owner</Button>
                                    {qrCodesentMsg ? <p className="txt-green mb-0 mt-4">{qrCodesentMsg}</p> : <p className="mb-0 mt-4">&nbsp;</p>}
                                    </>}
                                  </div>}
                                </>}

                                {entityItem.entity_data_type.statewisedisruptiondata === 1 && <div id="disruption-periods">
                                  {disruptionData && disruptionData.sort( (a,b) => b.timestamp - a.timestamp ).map((item,index) => {
                                    let quarters = item.DisruptionQuarters;
                                    return (<div className="disruption-wrapper">
                                      {/* <fieldset  disabled={index+1 !== disruptionData.length}> */}
                                      <fieldset disabled>
                                        {/* {index},{item.id},{item.timestamp},{item.DisruptionState},{item.DisruptionStartDate},{item.DisruptionEndDate},{item.DisruptionImpactAtleastTenPercent},{item.DisruptionComments},{item.DisruptionQuarters},{quarters} */}
                                        <Row key={index}>
                                          <Col xs={3}>
                                            <div><label><b>State</b></label></div>
                                            <Form.Select name="DisruptionState" value={item.DisruptionState} onChange={handleChangeDisruptionData} style={{minWidth:'100%', maxWidth:'100%'}}>
                                              <option value="">Select</option>
                                              {stateLookupList.map(state => <option value={state}>{state}</option>)}
                                            </Form.Select>
                                          </Col>
                                          <Col xs={3}>
                                            <div><label><b>Start Date</b></label></div>
                                            <Form.Control name="DisruptionStartDate" value={item.DisruptionStartDate} onChange={handleChangeDisruptionData} onKeyDown={handleDelete} type="date" min="2020-01-01" max="2021-12-31" />
                                          </Col>
                                          <Col xs={3}>
                                            <div><label><b>End Date</b></label></div>
                                            <Form.Control name="DisruptionEndDate" value={item.DisruptionEndDate} onChange={handleChangeDisruptionData} onKeyDown={handleDelete} type="date" min="2020-01-01" max="2021-12-31" />
                                          </Col>
                                          <Col xs={3}>
                                            <div><label><b>At least a 10% impact?</b></label></div>
                                            {item.DisruptionImpactAtleastTenPercent}
                                          </Col>
                                        </Row>
                                        {item.DisruptionReducedServiceHoursReasons && <div className="mt-2 mb-3 p-2" style={{backgroundColor: "#fafafa"}}>
                                          <label className="mr-2 mb-0">Causes:</label>
                                          <ul className="columns-3">{item.DisruptionReducedServiceHoursReasons.map((reason,i) => 
                                            <li className="mb-0"><b>{reason}</b>{reason.includes("Other") && <>: <span dangerouslySetInnerHTML={{__html: item.DisruptionOtherCircumstances}} /></>}</li>)}
                                          </ul>
                                        </div>}
                                        {item.DisruptionComments && <Row>
                                          <Col>
                                            <label className="mr-2 mb-0">Please provide an explanation of how your business was impacted during the period of disruption identified and, where possible, list the specific COVID-related governmental orders that caused the impact you checked above (i.e. State Executive Order 1,  State Executive Order 2, etc.):</label><span><b><span dangerouslySetInnerHTML={{__html: item.DisruptionComments}} /></b></span>
                                          </Col>
                                        </Row>}
                                      </fieldset>
                                      <Row className="mt-3">
                                        <Col><label className="mr-2 mb-0">Quarters affected:</label><span><b>{quarters && quarters.map((q,i) => <>{i+1 === quarters.length ? q : <>{q}, </>}</>)}</b></span></Col>
                                        <Col xs="auto">
                                          <Button variant="plain" title="Edit" onClick={() => handleShowEditDisruptionData(item.id, item.DisruptionState, item.DisruptionStartDate, item.DisruptionEndDate, item.DisruptionImpactAtleastTenPercent, item.DisruptionReducedServiceHoursReasons, item.DisruptionOtherCircumstances, item.DisruptionComments, quarters)}><img src={require('./../assets/images/edit-icon.png')} alt="Edit" height="20" className="mr-2" /></Button>
                                          <Button variant="plain" title="Delete" onClick={() => deleteDisruptionData(item.id)}><img src={require('./../assets/images/delete-icon.png')} alt="Delete" height="20" /></Button>
                                        </Col>
                                      </Row>
                                    </div>)
                                  })}
                                  
                                  <div className="disruption-wrapper">
                                    <fieldset id="stateWiseDisruptionDataEmpty">
                                      <Row>
                                        <Col xs={3}>
                                          <div><label><b>State</b></label></div>
                                          <Form.Select name="DisruptionState" defaultValue="" onChange={handleChangeDisruptionData} style={{minWidth:'100%', maxWidth:'100%'}}>
                                            <option value="">Select</option>
                                            {stateLookupList.map(state => <option value={state}>{state}</option>)}
                                          </Form.Select>
                                        </Col>
                                        <Col xs={3}>
                                          <div><label><b>Start Date</b></label></div>
                                          <Form.Control name="DisruptionStartDate" defaultValue="" onChange={handleChangeDisruptionData} onKeyDown={handleDelete} type="date" min="2020-01-01" max="2021-12-31" />
                                        </Col>
                                        <Col xs={3}>
                                          <div><label><b>End Date</b></label></div>
                                          <Form.Control name="DisruptionEndDate" defaultValue="" onChange={handleChangeDisruptionData} onKeyDown={handleDelete} type="date" min="2020-01-01" max="2021-12-31" />
                                        </Col>
                                        <Col xs={3}>
                                          <div>
                                            <label><b>At least a 10% impact?</b></label>
                                            <OverlayTrigger placement="top" overlay={<Tooltip>If "Yes" selected, please provide an explanation.</Tooltip>}>
                                              <span className="info"><i>i</i></span>
                                            </OverlayTrigger>
                                          </div>
                                          <Form.Check inline type="radio" name="DisruptionImpactAtleastTenPercent" value="Yes" onChange={handleChangeDisruptionData} label="Yes" defaultChecked="" />
                                          <Form.Check inline type="radio" name="DisruptionImpactAtleastTenPercent" value="No" onChange={handleChangeDisruptionData} label="No" defaultChecked="" />
                                        </Col>
                                      </Row>
                                      <div className="mt-2 mb-3 p-2" style={{backgroundColor: "#fafafa"}}>
                                        <div><label><b>Check any of the following that caused a disruption to your business operations and was a result of one or more COVID-related governmental orders.</b></label></div>
                                        <div className="options-wrapper" style={{columnCount: 3}}>{reducedServiceHrsReasonOptions && reducedServiceHrsReasonOptions.map((options, optionsIndex) =>
                                          <><Form.Check key={optionsIndex} type="checkbox" name="DisruptionReducedServiceHoursReasons" value={options} onChange={handleChangeDisruptionData} label={options} defaultChecked="" />
                                          {options.includes("Other") && <div className="d-none">
                                          <div><label className="mb-0"><b>Please specify</b></label></div>
                                          <Form.Control as="textarea" name="DisruptionOtherCircumstances" defaultValue="" placeholder="Type here..." onChange={handleChangeDisruptionData} onKeyDown={handleDelete} style={{ height: '100px' }} />
                                        </div>}</>
                                        )}</div>
                                        {/* <div className="d-none mt-2">
                                          <Form.Control as="textarea" name="DisruptionOtherCircumstances" defaultValue="" placeholder="Other. Type here..." onChange={handleChangeDisruptionData} onKeyDown={handleDelete} style={{ height: '100px' }} />
                                        </div> */}
                                      </div>
                                      <Row>
                                        <Col><div><label><b>Please provide an explanation of how your business was impacted during the period of disruption identified and, where possible, list the specific COVID-related governmental orders that caused the impact you checked above (i.e. State Executive Order 1,  State Executive Order 2, etc.)</b></label></div><Form.Control as="textarea" name="DisruptionComments" defaultValue="" placeholder="Type here..." onChange={handleChangeDisruptionData} onKeyDown={handleDelete} style={{ height: '100px' }} /></Col>
                                      </Row>
                                    </fieldset>
                                  </div>
                                  <Button variant="transparent" onClick={handleAddNew} disabled={addNewDisabled}>Add new period</Button>
                                </div>}

                                {entityItem.entity_data_type.locationwisedisruptiondata === 1 && <>
                                  {entityItem.entity_label && <>
                                    <Form.Label className="mr-3">
                                      <span dangerouslySetInnerHTML={{__html: entityItem.entity_label}} /> {entityItem.entity_mandatory === 1 && <span className='txt-red'>*</span>}
                                      {entityItem.entity_info &&
                                        <OverlayTrigger placement="right" overlay={<Tooltip><span dangerouslySetInnerHTML={{__html: entityItem.entity_info}} /></Tooltip>}>
                                          <span className="info"><i>i</i></span>
                                        </OverlayTrigger>
                                      }
                                    </Form.Label>
                                  </>}
                                  <div id="disruption-periods">
                                    {disruptionData && disruptionData.sort( (a,b) => b.timestamp - a.timestamp ).map((item,index) => {
                                      let quarters = item.DisruptionQuarters;
                                      return (<div className="disruption-wrapper">
                                        <fieldset disabled>
                                          <Row key={index}>
                                            <Col xs={3}>
                                              <div><label><b>Location</b></label></div>
                                              <Form.Control name="DisruptionLocation" value={item.DisruptionLocation} onChange={handleChangeLocationDisruptionData} onKeyDown={handleDelete} type="text" />
                                            </Col>
                                            <Col xs={3}>
                                              <div><label><b>Start Date</b></label></div>
                                              <Form.Control name="DisruptionLocStartDate" value={item.DisruptionLocStartDate} onChange={handleChangeLocationDisruptionData} onKeyDown={handleDelete} type="date" min="2020-01-01" max="2021-12-31" />
                                            </Col>
                                            <Col xs={3}>
                                              <div><label><b>End Date</b></label></div>
                                              <Form.Control name="DisruptionLocEndDate" value={item.DisruptionLocEndDate} onChange={handleChangeLocationDisruptionData} onKeyDown={handleDelete} type="date" min="2020-01-01" max="2021-12-31" />
                                            </Col>
                                          </Row>
                                          {item.DisruptionLocComments && <Row className="mt-4">
                                            <Col>
                                              <label className="mr-2 mb-0">Explanation:</label><span><b><span dangerouslySetInnerHTML={{__html: item.DisruptionLocComments}} /></b></span>
                                            </Col>
                                          </Row>}
                                        </fieldset>
                                        <Row className="mt-3">
                                          <Col><label className="mr-2 mb-0">Quarters affected:</label><span><b>{quarters && quarters.map((q,i) => <>{i+1 === quarters.length ? q : <>{q}, </>}</>)}</b></span></Col>
                                          <Col xs="auto">
                                            <Button variant="plain" title="Edit" onClick={() => handleShowEditLocationDisruptionData(item.id, item.DisruptionLocation, item.DisruptionLocStartDate, item.DisruptionLocEndDate, item.DisruptionLocComments, quarters)}><img src={require('./../assets/images/edit-icon.png')} alt="Edit" height="20" className="mr-2" /></Button>
                                            <Button variant="plain" title="Delete" onClick={() => deleteLocationDisruptionData(item.id)}><img src={require('./../assets/images/delete-icon.png')} alt="Delete" height="20" /></Button>
                                          </Col>
                                        </Row>
                                      </div>)
                                    })}
                                    
                                    <div className="disruption-wrapper">
                                      <fieldset id="locationWiseDisruptionDataEmpty" disabled>
                                        <Row>
                                          <Col xs={3}>
                                            <div><label><b>Location</b></label></div>
                                            <Form.Control name="DisruptionLocation" defaultValue="" onChange={handleChangeLocationDisruptionData} onKeyDown={handleDelete} type="text" />
                                          </Col>
                                          <Col xs={3}>
                                            <div><label><b>Start Date</b></label></div>
                                            <Form.Control name="DisruptionLocStartDate" defaultValue="" onChange={handleChangeLocationDisruptionData} onKeyDown={handleDelete} type="date" min="2020-01-01" max="2021-12-31" />
                                          </Col>
                                          <Col xs={3}>
                                            <div><label><b>End Date</b></label></div>
                                            <Form.Control name="DisruptionLocEndDate" defaultValue="" onChange={handleChangeLocationDisruptionData} onKeyDown={handleDelete} type="date" min="2020-01-01" max="2021-12-31" />
                                          </Col>
                                        </Row>
                                        <Row className="mt-4">
                                          <Col><div><label><b>Explanation</b></label></div><Form.Control as="textarea" name="DisruptionLocComments" defaultValue="" placeholder="Type here..." onChange={handleChangeLocationDisruptionData} onKeyDown={handleDelete} style={{ height: '100px' }} /></Col>
                                        </Row>
                                      </fieldset>
                                    </div>
                                    <Button variant="transparent" onClick={handleAddNewLocation} disabled={addNewDisabled}>Add new period</Button>
                                  </div>
                                </>}

                                {entityItem.entity_data_type.staffingdisruptiondata === 1 && <>
                                  {entityItem.entity_label && <>
                                    <Form.Label className="mr-3">
                                      <span dangerouslySetInnerHTML={{__html: entityItem.entity_label}} /> {entityItem.entity_mandatory === 1 && <span className='txt-red'>*</span>}
                                      {entityItem.entity_info &&
                                        <OverlayTrigger placement="right" overlay={<Tooltip><span dangerouslySetInnerHTML={{__html: entityItem.entity_info}} /></Tooltip>}>
                                          <span className="info"><i>i</i></span>
                                        </OverlayTrigger>
                                      }
                                    </Form.Label>
                                  </>}
                                  <div id="staffing-timeframes">
                                    <table className="table table-bordered">
                                      <thead>
                                        <tr>
                                          <th>Quarters</th>
                                          <th>Disruption experienced?</th>
                                          <th>Disrupted the entire quarter?</th>
                                          <th>Starting date</th>
                                          <th>Ending date</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <th>Q1 2020 (Jan 1 - Mar  31)</th>
                                          <td>
                                            <div className="d-flex flex-wrap">
                                              <Form.Check inline type="radio" name="DisruptionExperienced2020Q1" className="disruption-experienced" defaultChecked={inputsTimeframe.DisruptionExperienced2020Q1 === "Yes"} value="Yes" onChange={handleChangeTimeframe} onKeyDown={handleDelete}  id="DisruptionExperienced2020Q1Yes" label="Yes" />
                                              <Form.Check inline type="radio" name="DisruptionExperienced2020Q1" className="disruption-experienced" defaultChecked={inputsTimeframe.DisruptionExperienced2020Q1 === "No"} value="No" onChange={handleChangeTimeframe} onKeyDown={handleDelete}  id="DisruptionExperienced2020Q1No" label="No" />
                                            </div>
                                          </td>
                                          <td>
                                            <div>
                                              <Form.Check inline type="radio" name="DisruptedEntireQuarter2020Q1" className="entire-quarter" defaultChecked={inputsTimeframe.DisruptedEntireQuarter2020Q1 === "Yes"} value="Yes" onChange={handleChangeTimeframe} onKeyDown={handleDelete}  id="DisruptedEntireQuarter2020Q1Yes" label="Yes" disabled />
                                              <Form.Check inline type="radio" name="DisruptedEntireQuarter2020Q1" className="entire-quarter" defaultChecked={inputsTimeframe.DisruptedEntireQuarter2020Q1 === "No"} value="No" onChange={handleChangeTimeframe} onKeyDown={handleDelete}  id="DisruptedEntireQuarter2020Q1No" label="No" disabled />
                                            </div>
                                          </td>
                                          <td>
                                            <Form.Control name="StartDate2020Q1" className="disruption-date start-date" defaultValue={inputsTimeframe.StartDate2020Q1} onChange={handleChangeTimeframe} onKeyDown={handleDelete} type="date" min="2020-01-01" max="2020-03-31" disabled />
                                          </td>
                                          <td>
                                            <Form.Control name="EndDate2020Q1" className="disruption-date end-date" defaultValue={inputsTimeframe.EndDate2020Q1} onChange={handleChangeTimeframe} onKeyDown={handleDelete} type="date" min="2020-01-01" max="2020-03-31" disabled />
                                          </td>
                                        </tr>
                                        <tr>
                                          <th>Q2 2020 (Apr 1 - Jun  30)</th>
                                          <td>
                                            <div className="d-flex flex-wrap">
                                              <Form.Check inline type="radio" name="DisruptionExperienced2020Q2" className="disruption-experienced" defaultChecked={inputsTimeframe.DisruptionExperienced2020Q2 === "Yes"} value="Yes" onChange={handleChangeTimeframe} onKeyDown={handleDelete}  id="DisruptionExperienced2020Q2Yes" label="Yes" />
                                              <Form.Check inline type="radio" name="DisruptionExperienced2020Q2" className="disruption-experienced" defaultChecked={inputsTimeframe.DisruptionExperienced2020Q2 === "No"} value="No" onChange={handleChangeTimeframe} onKeyDown={handleDelete}  id="DisruptionExperienced2020Q2No" label="No" />
                                            </div>
                                          </td>
                                          <td>
                                            <div>
                                              <Form.Check inline type="radio" name="DisruptedEntireQuarter2020Q2" className="entire-quarter" defaultChecked={inputsTimeframe.DisruptedEntireQuarter2020Q2 === "Yes"} value="Yes" onChange={handleChangeTimeframe} onKeyDown={handleDelete}  id="DisruptedEntireQuarter2020Q2Yes" label="Yes" disabled />
                                              <Form.Check inline type="radio" name="DisruptedEntireQuarter2020Q2" className="entire-quarter" defaultChecked={inputsTimeframe.DisruptedEntireQuarter2020Q2 === "No"} value="No" onChange={handleChangeTimeframe} onKeyDown={handleDelete}  id="DisruptedEntireQuarter2020Q2No" label="No" disabled />
                                            </div>
                                          </td>
                                          <td>
                                            <Form.Control name="StartDate2020Q2" className="disruption-date start-date" defaultValue={inputsTimeframe.StartDate2020Q2} onChange={handleChangeTimeframe} onKeyDown={handleDelete} type="date" min="2020-04-01" max="2020-06-30" disabled />
                                          </td>
                                          <td>
                                            <Form.Control name="EndDate2020Q2" className="disruption-date end-date" defaultValue={inputsTimeframe.EndDate2020Q2} onChange={handleChangeTimeframe} onKeyDown={handleDelete} type="date" min="2020-04-01" max="2020-06-30" disabled />
                                          </td>
                                        </tr>
                                        <tr>
                                          <th>Q3 2020 (Jul 1 - Sep  30)</th>
                                          <td>
                                            <div className="d-flex flex-wrap">
                                              <Form.Check inline type="radio" name="DisruptionExperienced2020Q3" className="disruption-experienced" defaultChecked={inputsTimeframe.DisruptionExperienced2020Q3 === "Yes"} value="Yes" onChange={handleChangeTimeframe} onKeyDown={handleDelete}  id="DisruptionExperienced2020Q3Yes" label="Yes" />
                                              <Form.Check inline type="radio" name="DisruptionExperienced2020Q3" className="disruption-experienced" defaultChecked={inputsTimeframe.DisruptionExperienced2020Q3 === "No"} value="No" onChange={handleChangeTimeframe} onKeyDown={handleDelete}  id="DisruptionExperienced2020Q3No" label="No" />
                                            </div>
                                          </td>
                                          <td>
                                            <div>
                                              <Form.Check inline type="radio" name="DisruptedEntireQuarter2020Q3" className="entire-quarter" defaultChecked={inputsTimeframe.DisruptedEntireQuarter2020Q3 === "Yes"} value="Yes" onChange={handleChangeTimeframe} onKeyDown={handleDelete}  id="DisruptedEntireQuarter2020Q3Yes" label="Yes" disabled />
                                              <Form.Check inline type="radio" name="DisruptedEntireQuarter2020Q3" className="entire-quarter" defaultChecked={inputsTimeframe.DisruptedEntireQuarter2020Q3 === "No"} value="No" onChange={handleChangeTimeframe} onKeyDown={handleDelete}  id="DisruptedEntireQuarter2020Q3No" label="No" disabled />
                                            </div>
                                          </td>
                                          <td>
                                            <Form.Control name="StartDate2020Q3" className="disruption-date start-date" defaultValue={inputsTimeframe.StartDate2020Q3} onChange={handleChangeTimeframe} onKeyDown={handleDelete} type="date" min="2020-07-01" max="2020-09-30" disabled />
                                          </td>
                                          <td>
                                            <Form.Control name="EndDate2020Q3" className="disruption-date end-date" defaultValue={inputsTimeframe.EndDate2020Q3} onChange={handleChangeTimeframe} onKeyDown={handleDelete} type="date" min="2020-07-01" max="2020-09-30" disabled />
                                          </td>
                                        </tr>
                                        <tr>
                                          <th>Q4 2020 (Oct 1 - Dec  31)</th>
                                          <td>
                                            <div className="d-flex flex-wrap">
                                              <Form.Check inline type="radio" name="DisruptionExperienced2020Q4" className="disruption-experienced" defaultChecked={inputsTimeframe.DisruptionExperienced2020Q4 === "Yes"} value="Yes" onChange={handleChangeTimeframe} onKeyDown={handleDelete}  id="DisruptionExperienced2020Q4Yes" label="Yes" />
                                              <Form.Check inline type="radio" name="DisruptionExperienced2020Q4" className="disruption-experienced" defaultChecked={inputsTimeframe.DisruptionExperienced2020Q4 === "No"} value="No" onChange={handleChangeTimeframe} onKeyDown={handleDelete}  id="DisruptionExperienced2020Q4No" label="No" />
                                            </div>
                                          </td>
                                          <td>
                                            <div>
                                              <Form.Check inline type="radio" name="DisruptedEntireQuarter2020Q4" className="entire-quarter" defaultChecked={inputsTimeframe.DisruptedEntireQuarter2020Q4 === "Yes"} value="Yes" onChange={handleChangeTimeframe} onKeyDown={handleDelete}  id="DisruptedEntireQuarter2020Q4Yes" label="Yes" disabled />
                                              <Form.Check inline type="radio" name="DisruptedEntireQuarter2020Q4" className="entire-quarter" defaultChecked={inputsTimeframe.DisruptedEntireQuarter2020Q4 === "No"} value="No" onChange={handleChangeTimeframe} onKeyDown={handleDelete}  id="DisruptedEntireQuarter2020Q4No" label="No" disabled />
                                            </div>
                                          </td>
                                          <td>
                                            <Form.Control name="StartDate2020Q4" className="disruption-date start-date" defaultValue={inputsTimeframe.StartDate2020Q4} onChange={handleChangeTimeframe} onKeyDown={handleDelete} type="date" min="2020-10-01" max="2020-12-31" disabled />
                                          </td>
                                          <td>
                                            <Form.Control name="EndDate2020Q4" className="disruption-date end-date" defaultValue={inputsTimeframe.EndDate2020Q4} onChange={handleChangeTimeframe} onKeyDown={handleDelete} type="date" min="2020-10-01" max="2020-12-31" disabled />
                                          </td>
                                        </tr>
                                        <tr>
                                          <th>Q1 2021 (Jan 1 - Mar  31)</th>
                                          <td>
                                            <div className="d-flex flex-wrap">
                                              <Form.Check inline type="radio" name="DisruptionExperienced2021Q1" className="disruption-experienced" defaultChecked={inputsTimeframe.DisruptionExperienced2021Q1 === "Yes"} value="Yes" onChange={handleChangeTimeframe} onKeyDown={handleDelete}  id="DisruptionExperienced2021Q1Yes" label="Yes" />
                                              <Form.Check inline type="radio" name="DisruptionExperienced2021Q1" className="disruption-experienced" defaultChecked={inputsTimeframe.DisruptionExperienced2021Q1 === "No"} value="No" onChange={handleChangeTimeframe} onKeyDown={handleDelete}  id="DisruptionExperienced2021Q1No" label="No" />
                                            </div>
                                          </td>
                                          <td>
                                            <div>
                                              <Form.Check inline type="radio" name="DisruptedEntireQuarter2021Q1" className="entire-quarter" defaultChecked={inputsTimeframe.DisruptedEntireQuarter2021Q1 === "Yes"} value="Yes" onChange={handleChangeTimeframe} onKeyDown={handleDelete}  id="DisruptedEntireQuarter2021Q1Yes" label="Yes" disabled />
                                              <Form.Check inline type="radio" name="DisruptedEntireQuarter2021Q1" className="entire-quarter" defaultChecked={inputsTimeframe.DisruptedEntireQuarter2021Q1 === "No"} value="No" onChange={handleChangeTimeframe} onKeyDown={handleDelete}  id="DisruptedEntireQuarter2021Q1No" label="No" disabled />
                                            </div>
                                          </td>
                                          <td>
                                            <Form.Control name="StartDate2021Q1" className="disruption-date start-date" defaultValue={inputsTimeframe.StartDate2021Q1} onChange={handleChangeTimeframe} onKeyDown={handleDelete} type="date" min="2021-01-01" max="2021-03-31" disabled />
                                          </td>
                                          <td>
                                            <Form.Control name="EndDate2021Q1" className="disruption-date end-date" defaultValue={inputsTimeframe.EndDate2021Q1} onChange={handleChangeTimeframe} onKeyDown={handleDelete} type="date" min="2021-01-01" max="2021-03-31" disabled />
                                          </td>
                                        </tr>
                                        <tr>
                                          <th>Q2 2021 (Apr 1 - Jun  30)</th>
                                          <td>
                                            <div className="d-flex flex-wrap">
                                              <Form.Check inline type="radio" name="DisruptionExperienced2021Q2" className="disruption-experienced" defaultChecked={inputsTimeframe.DisruptionExperienced2021Q2 === "Yes"} value="Yes" onChange={handleChangeTimeframe} onKeyDown={handleDelete}  id="DisruptionExperienced2021Q2Yes" label="Yes" />
                                              <Form.Check inline type="radio" name="DisruptionExperienced2021Q2" className="disruption-experienced" defaultChecked={inputsTimeframe.DisruptionExperienced2021Q2 === "No"} value="No" onChange={handleChangeTimeframe} onKeyDown={handleDelete}  id="DisruptionExperienced2021Q2No" label="No" />
                                            </div>
                                          </td>
                                          <td>
                                            <div>
                                              <Form.Check inline type="radio" name="DisruptedEntireQuarter2021Q2" className="entire-quarter" defaultChecked={inputsTimeframe.DisruptedEntireQuarter2021Q2 === "Yes"} value="Yes" onChange={handleChangeTimeframe} onKeyDown={handleDelete}  id="DisruptedEntireQuarter2021Q2Yes" label="Yes" disabled />
                                              <Form.Check inline type="radio" name="DisruptedEntireQuarter2021Q2" className="entire-quarter" defaultChecked={inputsTimeframe.DisruptedEntireQuarter2021Q2 === "No"} value="No" onChange={handleChangeTimeframe} onKeyDown={handleDelete}  id="DisruptedEntireQuarter2021Q2No" label="No" disabled />
                                            </div>
                                          </td>
                                          <td>
                                            <Form.Control name="StartDate2021Q2" className="disruption-date start-date" defaultValue={inputsTimeframe.StartDate2021Q2} onChange={handleChangeTimeframe} onKeyDown={handleDelete} type="date" min="2021-04-01" max="2021-06-30" disabled />
                                          </td>
                                          <td>
                                            <Form.Control name="EndDate2021Q2" className="disruption-date end-date" defaultValue={inputsTimeframe.EndDate2021Q2} onChange={handleChangeTimeframe} onKeyDown={handleDelete} type="date" min="2021-04-01" max="2021-06-30" disabled />
                                          </td>
                                        </tr>
                                        <tr>
                                          <th>Q3 2021 (Jul 1 - Sep  30)</th>
                                          <td>
                                            <div className="d-flex flex-wrap">
                                              <Form.Check inline type="radio" name="DisruptionExperienced2021Q3" className="disruption-experienced" defaultChecked={inputsTimeframe.DisruptionExperienced2021Q3 === "Yes"} value="Yes" onChange={handleChangeTimeframe} onKeyDown={handleDelete}  id="DisruptionExperienced2021Q3Yes" label="Yes" />
                                              <Form.Check inline type="radio" name="DisruptionExperienced2021Q3" className="disruption-experienced" defaultChecked={inputsTimeframe.DisruptionExperienced2021Q3 === "No"} value="No" onChange={handleChangeTimeframe} onKeyDown={handleDelete}  id="DisruptionExperienced2021Q3No" label="No" />
                                            </div>
                                          </td>
                                          <td>
                                            <div>
                                              <Form.Check inline type="radio" name="DisruptedEntireQuarter2021Q3" className="entire-quarter" defaultChecked={inputsTimeframe.DisruptedEntireQuarter2021Q3 === "Yes"} value="Yes" onChange={handleChangeTimeframe} onKeyDown={handleDelete}  id="DisruptedEntireQuarter2021Q3Yes" label="Yes" disabled />
                                              <Form.Check inline type="radio" name="DisruptedEntireQuarter2021Q3" className="entire-quarter" defaultChecked={inputsTimeframe.DisruptedEntireQuarter2021Q3 === "No"} value="No" onChange={handleChangeTimeframe} onKeyDown={handleDelete}  id="DisruptedEntireQuarter2021Q3No" label="No" disabled />
                                            </div>
                                          </td>
                                          <td>
                                            <Form.Control name="StartDate2021Q3" className="disruption-date start-date" defaultValue={inputsTimeframe.StartDate2021Q3} onChange={handleChangeTimeframe} onKeyDown={handleDelete} type="date" min="2021-07-01" max="2021-09-30" disabled />
                                          </td>
                                          <td>
                                            <Form.Control name="EndDate2021Q3" className="disruption-date end-date" defaultValue={inputsTimeframe.EndDate2021Q3} onChange={handleChangeTimeframe} onKeyDown={handleDelete} type="date" min="2021-07-01" max="2021-09-30" disabled />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </>}

                                {entityItem.entity_data_type.is_signature_status === 1 && <>
                                  {entityItem.entity_data && <>
                                    {JSON.parse(entityItem.entity_data).value === 'toSend' && 'The Engagement Agreement is ready. You will soon receive a request on your mail to review and sign the same.'}
                                    {JSON.parse(entityItem.entity_data).value === 'sent' && 'Engagement Agreement has been mailed to you. Please review and sign it.'}
                                    {JSON.parse(entityItem.entity_data).value === 'signed' && 'We have received the signed Engagement Agreement.'}
                                  </>}
                                </>}
                              </>
                            }
                            {//type = acknowledgement
                              entityItem.entity_data_type.type === 'acknowledgement' && <>
                                {entityItem.entity_data ? <>
                                  <p className="h5"><img src={require('./../assets/images/tick-star.png')} alt="completed" className="mr-2" />Accepted by <b>{JSON.parse(entityItem.entity_data).SignerName}</b> on <b>{JSON.parse(entityItem.entity_data).SignerDate}</b></p>
                                </>
                                : <>{/* {!acknowledgeInitiated && <Button variant="primary" onClick={() => setAcknowledgeInitiated(true)}>Acknowledge and Sign</Button>} */}
                                {/* acknowledgeInitiated &&  */<div className="acknowledge">
                                  {entityItem.entity_data_type.items.map((item, index) => {
                                    return(<>
                                    {item.type === 'checkbox' ?
                                      <div><Form.Check type="checkbox" name={item.name} defaultChecked="" label={<>{item.label} <span className='txt-red'>*</span></>} onChange={handleChange} id={item.name} />
                                      </div>
                                      : <>
                                      {item.name === 'SignerName' && <div className="mb-3"><b>{cdCompanyName}</b></div>}
                                      <Row key={index} className="mb-3 w-50">
                                        <Col xs="auto">
                                          <Form.Label>{item.label} <span className="txt-red">*</span></Form.Label>
                                          {item.info && <OverlayTrigger placement="top" overlay={<Tooltip>{item.info}</Tooltip>}>
                                            <span className="info"><i>i</i></span>
                                          </OverlayTrigger>}
                                        </Col>
                                        <Col><Form.Control name={item.name} defaultValue="" onChange={handleChange} onKeyDown={handleDelete} type={item.type} min={item.type === 'date' && new Date().toISOString().split("T")[0]} max={item.type === 'date' && new Date().toISOString().split("T")[0]} /></Col>
                                      </Row>
                                    </>}
                                    </>)
                                  })}
                                  <div className="mt-5"><Button variant="primary" onClick={(e) => handleAcknowledgeSubmit(entityItem.entity_name, e)}>I Accept</Button></div>
                                </div>}
                              </>}
                            </>
                            }
                            {//type = naics_code_lookup
                              entityItem.entity_data_type.type === 'naics_code_lookup' && <>
                                {entityItem.entity_label && <>
                                  <Form.Label>
                                    <span dangerouslySetInnerHTML={{__html: entityItem.entity_label}} /> {entityItem.entity_mandatory === 1 && <span className='txt-red'>*</span>}
                                    {entityItem.entity_info &&
                                      <OverlayTrigger placement="right" overlay={<Tooltip><span dangerouslySetInnerHTML={{__html: entityItem.entity_info}} /></Tooltip>}>
                                        <span className="info"><i>i</i></span>
                                      </OverlayTrigger>
                                    }
                                  </Form.Label>
                                </>}
                                <Form.Control list={entityItem.entity_name} name={entityItem.entity_name} defaultValue={(inputs[entityItem.entity_name] && inputs[entityItem.entity_name].replace(/&apos;/g,"'")) || (entityItem.entity_data && entityItem.entity_data.replace(/&apos;/g,"'"))} onChange={handleChange} onKeyDown={handleDelete} type="text" />
                                <datalist id={entityItem.entity_name}>
                                  <option></option>
                                  {naicsCodeLookup &&
                                    naicsCodeLookup.map((item, index) => <option key={index} value={item.naics_code +' '+item.naics_title}>{item.naics_code +' '+item.naics_title}</option>)
                                  }
                                </datalist>
                              </>
                            }
                            </Form.Group> 
                          }
                          
                        </>)
                      })}
                    </div>
                  }

                  {(sectionIndex === 1 && (sectionItem.section_name === "customer_data_gross_receipts_2019" || sectionItem.section_name === "customer_data_gross_receipts_2020" || sectionItem.section_name === "customer_data_gross_receipts_2021")) && <p style={{width: '90%', position: 'absolute', left: '15px'}}><i>*Note-gross receipts above should include sales net of returns and allowances, all amounts received for services, income from investments, interest, dividends, rents, royalties or annuities. Client represents amounts above are complete and accurate.</i></p>}

                  {(entityName === 'employee_info' && sectionItem.section_name === "employee_info_top") && <>
                    <h5 className="mb-3">Please provide the number of W2 employees below. Full time employees are those that work 130 hours/month or 390 hours/quarter.</h5>
                    <Row className="quarter-table-header">
                      <Col><b>Year</b></Col>
                      <Col><b>Average number of Full-time W2 Employees</b></Col>
                      <Col><b>Average number of Part-time W2 Employees</b></Col>
                    </Row>
                  </>}

                  {sectionItem.section_footer && <p>{sectionItem.section_footer}</p>}

                  {(entityName === 'final_form_941x' && sectionItem.section_name === 'signed_final_form_941x' && finalForm941xSigned) && <b>Signed Final Form 941x has been uploaded !</b>}
                </section></>)
              })
            }
          </div>

          {groupData.group_footer && <p>{groupData.group_footer}</p>}
          </fieldset>

          {sectionData.length !== 0 && <>
            {(entityName === 'agreements_disclosures' && formSubmitted) && <b>Form has already been submitted !</b>}
            {(entityName === 'submit_for_onboarding' && onboardingFormSubmitted) && <b>Onboarding form has been submitted{onboardingFormAccepted && <> and accepted</>} !</b>}
            {/* {(entityName === 'engagement_agreement' && eaSubmitted) && <b>Engagement Agreement has been submitted{eaAccepted && <> and accepted</>} !</b>} */}
            {(entityName === 'statement_of_fact' && sofSigned) && <b>Signed Statement of Fact has been uploaded !</b>}
            
            {/* {entityName === 'final_form_941x' && <Button variant="primary" type="button" onClick={handleDownloadAll}>Download All</Button>} */}
            
            <Row className="btn-container">
              <Col xs="auto">
                {/* {entityName},{stepNum}, {stepLength} */}
                {stepNum === stepNumber ? <Button variant="transparent" onClick={e => window.location.href="/"}>Cancel</Button> : <Button variant="transparent" onClick={prevStep}>Previous</Button>}
              </Col>
              {groupData.editable==='1' && <>
              {(entityName !== 'business_owner_id' && entityName !== 'form_941' && entityName !== 'form_941x' && entityName !== 'final_form_941x' && entityName !== 'power_of_attorney' && entityName !== 'additional_documents' && entityName !== 'agreements_disclosures' && entityName !== 'onboarding_questionnaire' && entityName !== 'submit_for_onboarding' && entityName !== 'statement_of_fact' && entityName !== 'engagement_agreement') && <Col xs="auto">
                <Button variant="primary" onClick={handleSaveFormData}>Save</Button>
              </Col>}
              </>}
              <Col xs="auto">
                {entityName === 'agreements_disclosures' ? (formSubmitted ? <Button variant="primary" type="submit">Next</Button> : <Button variant="primary" type="submit" /* disabled={submitDisabled} */>Submit</Button>) : 
                (/* entityName === 'engagement_agreement' ? (eaSubmitted ? (eaAccepted ? <Button variant="primary" type="submit">Next</Button> : <>&nbsp;</>) : <Button variant="primary" type="submit" disabled={submitDisabled}> Submit</Button>) : */ 
                (entityName === 'submit_for_onboarding' ? (onboardingFormSubmitted ? (onboardingFormAccepted ? <Button variant="primary" type="submit">Next</Button> : <>&nbsp;</>) : <Button variant="primary" type="submit" disabled={submitDisabled}>Submit </Button>) : (stepNum === stepLength ? null : <Button variant="primary" type="submit">Next</Button>)))
                }
              </Col>
            </Row>
            {(entityName === 'agreements_disclosures' && !formSubmitted) && <>
              {pendingEntitiesData.length > 0 && <>
                <h5 className="txt-red"><i className="fas fa-exclamation-triangle mr-1"></i> You have missing items in the following sections. Please click on it to be directed to the corresponding page.</h5>
                <ul className="list-style-disc">
                  {pendingEntitiesData.map((item, index) => {
                    return(<>
                      <li><Button variant="plain" className="text-left" onClick={() => handleSetNav(item.group)}><u>{item.group}</u></Button></li>
                    </>)
                  })}
                </ul>
              </>}
            </>}
          </>}

          {entityName === 'thank_you_so' &&
            <div className="text-center mt-5">
              <img src={require('./../assets/images/tick-circle.png')} alt="Thankyou" height="60" className='mb-3' />
              <h5><strong>Thank you!</strong></h5>
              <p>The Onboarding form has been submitted. Your Engagement Agreement is being prepared. You will receive an email notification once it is ready.</p>
            </div>
          }

          {entityName === 'thank_you_ea' &&
            <div className="text-center mt-5">
              <img src={require('./../assets/images/tick-circle.png')} alt="Thankyou" height="60" className='mb-3' />
              <h5><strong>Thank you!</strong></h5>
              <p>Your Engagement Agreement has been submitted.</p>
            </div>
          }

          {entityName === 'thank_you' &&
            <div className="text-center mt-5">
              <img src={require('./../assets/images/tick-circle.png')} alt="Thankyou" height="60" className='mb-3' />
              <h5><strong>Thank you!</strong></h5>
              <p>Your form is submitted</p>
            </div>
          }
        </Container>
      </Form>
      <Modal show={showEditDisruptionData} onHide={handleCloseEditDisruptionData} centered backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Edit data</Modal.Title>
        </Modal.Header>
        <Form onSubmit={(e) => updateDisruptionData(e,disruptionModalData.DisruptionDataId)}>
          <Modal.Body>
            <Row>
              <Col sm={3}>
                <div><label><b>State</b></label></div>
                <Form.Select name="DisruptionState" defaultValue={disruptionModalData.DisruptionState} onChange={handleEditDisruptionData} style={{minWidth:'100%', maxWidth:'100%'}} required>
                  <option value="">Select</option>
                  {stateLookupList.map(state => <option value={state}>{state}</option>)}
                </Form.Select>
              </Col>
              <Col sm={3}>
                <div><label><b>Start Date</b></label></div>
                <Form.Control name="DisruptionStartDate" defaultValue={disruptionModalData.DisruptionStartDate} onChange={handleEditDisruptionData} onKeyDown={handleDelete} type="date" min="2020-01-01" max="2021-12-31" required />
              </Col>
              <Col sm={3}>
                <div><label><b>End Date</b></label></div>
                <Form.Control name="DisruptionEndDate" defaultValue={disruptionModalData.DisruptionEndDate} onChange={handleEditDisruptionData} onKeyDown={handleDelete} type="date" min={disruptionModalData.DisruptionStartDate} max="2021-12-31" required />
              </Col>
              <Col sm={3}>
                <div>
                  <label><b>At least a 10% impact?</b></label>
                  <OverlayTrigger placement="top" overlay={<Tooltip>If "Yes" selected, please provide an explanation.</Tooltip>}>
                    <span className="info"><i>i</i></span>
                  </OverlayTrigger>
                </div>
                <Form.Check inline type="radio" name="DisruptionImpactAtleastTenPercent" value="Yes" onChange={handleEditDisruptionData} label="Yes" defaultChecked={disruptionModalData.DisruptionImpactAtleastTenPercent === "Yes"} />
                <Form.Check inline type="radio" name="DisruptionImpactAtleastTenPercent" value="No" onChange={handleEditDisruptionData} label="No" defaultChecked={disruptionModalData.DisruptionImpactAtleastTenPercent === "No"} />
              </Col>
            </Row>
            <div className="mt-2 mb-3 p-2">
              <div><label><b>Check any of the following that caused a disruption to your business operations and was a result of one or more COVID-related governmental orders.</b></label></div>
              <div className="options-wrapper" style={{columnCount: 3}}>{reducedServiceHrsReasonOptions && reducedServiceHrsReasonOptions.map((options, optionsIndex) =>
                <><Form.Check key={optionsIndex} type="checkbox" name="DisruptionReducedServiceHoursReasons" value={options} onChange={handleEditDisruptionData} label={options} defaultChecked={disruptionModalData.DisruptionReducedServiceHoursReasons && disruptionModalData.DisruptionReducedServiceHoursReasons.includes(options)} />
                {options.includes("Other") && <div className={disruptionModalData.DisruptionOtherCircumstances ? "d-block" : "d-none"}>
                <div><label className="mb-0"><b>Please specify</b></label></div>
                <Form.Control as="textarea" name="DisruptionOtherCircumstances" defaultValue={disruptionModalData.DisruptionOtherCircumstances && disruptionModalData.DisruptionOtherCircumstances.replace(/<br\s*\/?>/gi,'\n').replace(/&apos;/g,"'")} placeholder="Type here..." onChange={handleEditDisruptionData} onKeyDown={handleDelete} style={{ height: '100px' }} />
              </div>}</>
              )}</div>
            </div>
            <Row>
              <Col><Form.Label><b>Please provide an explanation of how your business was impacted during the period of disruption identified and, where possible, list the specific COVID-related governmental orders that caused the impact you checked above (i.e. State Executive Order 1,  State Executive Order 2, etc.)</b></Form.Label><Form.Control as="textarea" name="DisruptionComments" defaultValue={disruptionModalData.DisruptionComments && disruptionModalData.DisruptionComments.replace(/<br\s*\/?>/gi,'\n').replace(/&apos;/g,"'")} placeholder="Type here..." onChange={handleEditDisruptionData} onKeyDown={handleDelete} style={{ height: '100px' }} /></Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">Save</Button>
            <Button variant="transparent" onClick={handleCloseEditDisruptionData}>Cancel</Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={showEditLocationDisruptionData} onHide={handleCloseEditLocationDisruptionData} centered backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Edit data</Modal.Title>
        </Modal.Header>
        <Form onSubmit={(e) => updateLocationDisruptionData(e,locationDisruptionModalData.DisruptionDataId)}>
          <Modal.Body>
            <Row>
              <Col sm={3}>
                <div><label><b>Location</b></label></div>
                <Form.Control name="DisruptionLocation" defaultValue={locationDisruptionModalData.DisruptionLocation} onChange={handleEditLocationDisruptionData} onKeyDown={handleDelete} type="text" />
              </Col>
              <Col sm={3}>
                <div><label><b>Start Date</b></label></div>
                <Form.Control name="DisruptionLocStartDate" defaultValue={locationDisruptionModalData.DisruptionLocStartDate} onChange={handleEditLocationDisruptionData} onKeyDown={handleDelete} type="date" min="2020-01-01" max="2021-12-31" required />
              </Col>
              <Col sm={3}>
                <div><label><b>End Date</b></label></div>
                <Form.Control name="DisruptionLocEndDate" defaultValue={locationDisruptionModalData.DisruptionLocEndDate} onChange={handleEditLocationDisruptionData} onKeyDown={handleDelete} type="date" min={locationDisruptionModalData.DisruptionLocStartDate} max="2021-12-31" required />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col><Form.Label><b>Explanation</b></Form.Label><Form.Control as="textarea" name="DisruptionLocComments" defaultValue={locationDisruptionModalData.DisruptionLocComments && locationDisruptionModalData.DisruptionLocComments.replace(/<br\s*\/?>/gi,'\n').replace(/&apos;/g,"'")} placeholder="Type here..." onChange={handleEditLocationDisruptionData} onKeyDown={handleDelete} style={{ height: '100px' }} /></Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">Save</Button>
            <Button variant="transparent" onClick={handleCloseEditLocationDisruptionData}>Cancel</Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={showErrorPopup} onHide={handleCloseErrorPopup} centered backdrop="static" keyboard={false} className="modal-small">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <p className="text-center"><i className="fas fa-exclamation-triangle mr-2"></i> {errorPopupText}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="transparent" onClick={handleCloseErrorPopup}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showNotEligible} onHide={handleCloseNotEligible} centered backdrop="static" keyboard={false} className="modal-small">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <p className="text-center"><i className="fas fa-exclamation-triangle mr-2"></i> Sorry! You are not eligible for ERZ Credit.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="transparent" onClick={handleCloseNotEligible}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal className='show-picture' show={showFileModal} onHide={() => {setShowFileModal(false); setFile('')}} backdrop="static"keyboard={false} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <embed src={file} width='100%' height="600" />
        </Modal.Body>
      </Modal>

      {showAlertText && <div id="show-alert">
        <div>{showAlertText}</div>
      </div>}
    </div>
  );
};

export default FormSubmit;
